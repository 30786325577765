import Vue from 'vue';
import {store} from '../store/index.ts';

const { WEB_BASE_URL,WEB_APPID,WEB_SSOLOGIN_URL} = window.__appBaseConfig__||{};
import { Dialog } from 'vant';

const Config = {
  appId:WEB_APPID,
  callBackWebUrl: `${window.location.origin}${window.location.pathname}#/index`,
};

const loginUrl = `${WEB_SSOLOGIN_URL}?appId=${Config.appId}&callBackApi=${encodeURIComponent(Config.callBackWebUrl)}`

export function ssoLogin() {
  console.error(loginUrl)
  window.location.replace(loginUrl)
}

export function ssoEditor() {
  console.error(Config.ssoEditor)
  window.location.replace(Config.ssoEditor)
}

export function isLogin() {
  console.log(store, '------------------Store')
  if (!store.state.userInfo || !(store.state.userInfo && store.state.userInfo.userId)) {
    Dialog.confirm({
      message: "该功能需要登录后使用",
      confirmButtonColor:'#028bff',
      confirmButtonText:'登录',
      cancelButtonText:'取消'
    }).then(() => {
      store.dispatch("savePathInfo", { url: window.location.href })
      store.dispatch("LOGIN", { token: "" }).then(
        s => {},
        e => {
          console.error(e)
        }
      )
    }).catch(()=>{})
    return false
  }
  return true
}

window.testLogin=()=>{
  console.error(loginUrl)
  window.location.replace(loginUrl)
}

export default {};