// axios
import request from '@/utils/request';

/* 书详情接口 */
export const API = {
  getCateGoryList: '/dev-api/webApi/category/getCateGoryList', /* 获取所有分类以及分类对应的资源数量 内容类型：53-图书，54-有声书，55-课程对应字典book_type多个逗号隔开*/
  getAllPublish: `/dev-api/webApi/book/getAllPublish`,
  getSpecialPublish: `/dev-api/webApi/special/getAllPublish`,
  getCateGoryByLevel: `/dev-api/webApi/category/getCateGoryByLevel`
}

/* 根据id获取类型 */
export function getCateGoryByLevel(data) {
  return request({
    url: API.getCateGoryByLevel,
    method: 'get',
    params: data,
  });
}


/* 根据id获取类型 */
export function getCateGoryList(data) {
  return request({
    url: API.getCateGoryList,
    method: 'get',
    params: data,
  });
}

/* 获取出版社 */
export function getAllPublish(data) {
  return request({
    url: API.getAllPublish,
    method: 'get',
    params: data,
  });
}

/* 获取专题出版社 */
export function getSpecialPublish(data) {
  return request({
    url: API.getSpecialPublish,
    method: 'get',
    params: data,
  });
}