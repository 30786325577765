const state = {
  CatalogueId: '',
};
const mutations = {
  SET_CATALOGUEID (state, value) {
    state.CatalogueId = value
  },
};
export default {
  state,
  mutations,
};
