<template>
  <div class="breadcrumb-pager flexRowS my10">
    <img src="@/assets/images/icons/home.png" />
    <span class="cursor" @click="$router.replace('/')">首页</span>
    <span v-for="(text, index) in breadList" :key="index">
      <i>></i>
      <b
        v-if="text && text.name"
        class="cursor"
        @click="$router.replace(text.path)"
      >
        {{ text.name }}
      </b>
      <template v-else>
        {{ text }}
      </template>
    </span>
  </div>
</template>

<script>
export default {
  props: {
    breadList: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="less" scoped>
.breadcrumb-pager {
  font-size: 14px;
  color: #8d9399;
  img {
    width: 12px;
    margin-right: 4px;
  }
  span,
  i {
    margin-right: 8px;
  }
  > span:last-child {
    color: #0075e2;
    i {
      color: rgba(0, 0, 0, 0.45);
    }
  }
  b {
    font-weight: 400;
  }
}
</style>
