import { specialInfo } from '@/api/special';
/* 图书类型 图书类型：53-图书，54-有声书，55-视频，64-条目文档对应字典book_type */
export const BookType = {
  ebook: 53,
  dbook: 101,
  sbook: 54,
  course: 55,
  other: 64,
  special: 1,
  none: '',
};

/* 书类型对应详情跳转地址 */
export const BookTypeDetail = {
  53: '/detail',
  101: '/szjcDetail',
  54: '/soundBook',
  55: '/courseBook',
  64: '/other',
  0: '/special',
};

export function goDetail (item, content) {
  if (item.deleteFlag === '1') {
    content.$toast('本资源已删除,请联系平台客服');
    return;
  }

  if (
    (item.IsOnShelf !== undefined && item.IsOnShelf !== '1') ||
    item.deleteFlag === '1'
  ) {
    content.$toast('本资源已下架,请联系平台客服');
    return;
  }

  /* 图书类跳转 */
  const jumpUrl = BookTypeDetail[item.objectType || item.ObjectType];
  const url = `${jumpUrl}?id=${item.bookId || item.resourceId || item.Id || item.id
    }`;
  content.$router.push(url);
}

export function openNewDetail (item, content) {
  if (item.deleteFlag === '1') {
    content.$toast('本资源已删除,请联系平台客服');
    return;
  }

  if (
    (item.IsOnShelf !== undefined && item.IsOnShelf !== '1') ||
    item.deleteFlag === '1'
  ) {
    content.$toast('本资源已下架,请联系平台客服');
    return;
  }

  /* 图书类跳转 */
  const jumpUrl = BookTypeDetail[item.objectType || item.ObjectType];
  const url = `${jumpUrl}?id=${item.bookId || item.resourceId || item.Id || item.id
    }`;

  const href = content.$router.resolve(url)
  let local = window.location
  console.log(local, '------------------local')
  
  let toPath = local.origin + local.pathname + `#${href.resolved.fullPath}`
  console.log(toPath, '------------href------')
  // return
  window.open(toPath, "_blank");

}

/* 专题跳转处理 */
export function specialJump (book, content) {
  async function getSpecialInfo () {
    const { code, msg, data } = await specialInfo({
      id: book.resourceId || book.specialSubjectId || book.Id || book.id,
    });
    // console.log('code, msg, rows', code, msg, rows);
    if (code === 200) {
      return data;
    } else {
      Toast(msg);
      return {};
    }
  }
  async function goDetail () {
    const { resourceType, id } = await getSpecialInfo();
    if (resourceType * 1 === 2 || resourceType * 1 === 3) {
      content.$router.push(`/special/detail?id=${id}`);
    } else {
      content.$router.push(`/special/subSpecial?id=${id}`);
    }
  }

  goDetail();
}
