// axios
import request from '@/utils/request';

/* 书详情接口 */
export const API = {
  bookDetail:
    '/dev-api/webApi/book/getBookInfoById' /* 书架列表  ObjectType 内容类型：1专题 53-图书，54-有声书，55-视频，64-条目文档 */,
  menuList: '/dev-api/webApi/book/getBookCatalog' /* 书单 */,
  getBookCatalogForRead: '/dev-api/webApi/book/getBookCatalogForRead', /* 阅读书单 */
  resourceList: '/dev-api/webApi/book/getBookAdditional' /* 附加资源 */,
  pushList:
    '/dev-api/webApi/book/getBookRelevantRecommendations' /* 获取相关推荐 */,
  newPushList:
    '/dev-api/webApi/correlation/list2correlation' /* 获取相关推荐 */,
  serach: '/dev-api/webApi/book/searchBookList' /* 搜索电子书 */,
  bookListBySpecil:
    '/dev-api/webApi/book/getBookListBySubIdObjectType' /* 根据专题id 查询图书列表 分页 */,
  newBooks: '/dev-api/webApi/book/bookNews' /* 新品推荐 */,
  dicList: '/dev-api/webApi/dictData/getInfo' /* 查询字典数据 */,
  topClick:
    '/dev-api/webApi/book/clickThroughRate' /* 点击根据点击率获取电子书资源 */,
  userRead: '/dev-api/webApi/userRead/userRead' /* 阅读的时候调用下 */,
  readHeart: '/dev-api/webApi/userBookRead/readHeart' /* 阅读心跳 */,
  overRead: '/dev-api/webApi/userBookRead/overRead' /* 完播接口 */,
  yearRange: '/dev-api/webApi/book/getSearchDate' /* 查询电子书时间范围 */,
  playList:
    '/dev-api/webApi/userRead/getUserReadList' /* 用户已读列表 用于最近播放 */,
  getBookCatalogueInfo: '/dev-api/webApi/book/getBookCatalogueInfo',
  getBookCountByType: '/dev-api/webApi/book/getBookCountByType',
  getCountBook: '/dev-api/webApi/book/getCountBook',

  addReadNum: `/dev-api/webApi/book/addReadNum`,
  addSpecialNum: `/dev-api/webApi/special/addReadNum`,
  getPlatformIsRecommendApi: `/dev-api/webApi/platformConfig/getPlatformIsRecommend
  `,
  getNumBookTypeApi: `/dev-api/webApi/book/getNumBookType`
};

/* 是否开启榜单推荐功能 */
export function getNumBookTypeApi(data) {
  return request({
    url: API.getNumBookTypeApi,
    method: 'get',
    params: data,
  });
}

/* 是否开启榜单推荐功能 */
export function getPlatformIsRecommendApi(data) {
  // userRead({ resourceId: data.id, resourceType: 54 });
  return request({
    url: API.getPlatformIsRecommendApi,
    method: 'get',
    params: data,
  });
}

/* 根据id查询详情 */
export function getInfoById(data) {
  // userRead({ resourceId: data.id, resourceType: 54 });
  return request({
    url: API.bookDetail,
    method: 'get',
    params: data,
  });
}

/* 添加阅读量 */
export function addReadNum(data) {
  return request({
    url: API.addReadNum,
    method: 'post',
    params: data,
  });
}

/* 添加专题阅读量 */
export function addSpecialNum(data) {
  return request({
    url: API.addSpecialNum,
    method: 'post',
    params: data,
  });
}

/* 根据书id查询目录 */
export function getMenuByBookId(data) {
  return request({
    url: API.menuList,
    method: 'get',
    params: data,
  });
}

/* 根据书id查询已排序目录 */
export function getBookCatalogForRead(data) {
  return request({
    url: API.getBookCatalogForRead,
    method: 'get',
    params: data
  });
}

/* 根据书id查询附加资源 */
export function getResouceByBookId(data) {
  return request({
    url: API.resourceList,
    method: 'get',
    params: data,
  });
}

/* 根据书id查询推荐资源  新 */
export function newGetPushByBookId(data) {
  return request({
    url: API.newPushList,
    method: 'get',
    params: data,
  });
}

/* 根据书id查询推荐资源 */
export function getPushByBookId(data) {
  return request({
    url: API.pushList,
    method: 'get',
    params: data,
  });
}

/* 搜索电子书 */
export function searchBook(data) {
  return request({
    url: API.serach,
    method: 'get',
    params: data,
  });
}

/* 获取专题电子书列表 */
export function bookListBySpecil(data) {
  return request({
    url: API.bookListBySpecil,
    method: 'get',
    params: data,
  });
}

/* 新品推荐 */
export function newBooks(data) {
  return request({
    url: API.newBooks,
    method: 'get',
    params: data,
  });
}

/* 查询字典数据 */
export function dicList(data) {
  return request({
    url: API.dicList,
    method: 'get',
    params: data,
  });
}

/* 点击top */
export function topClick(data) {
  return request({
    url: API.topClick,
    method: 'get',
    params: data,
  });
}

/* 用户阅读 */
export function userRead(data) {
  return request({
    url: API.userRead,
    method: 'get',
    params: data,
  });
}

/* 阅读心跳 */
export function readHeart({ bookId, catalogueId, speedOfProgress }) {
  return request({
    url: API.readHeart,
    method: 'get',
    params: {
      bookId,
      catalogueId,
      speedOfProgress
    },
  });
}

/* 完播接口 */
export function overRead(catalogueId) {
  return request({
    url: API.overRead,
    method: 'get',
    params: {
      catalogueId,
    },
  });
}

/* 电子书时间跨度 */
export function yearRange(data) {
  return request({
    url: API.yearRange,
    method: 'get',
    params: data,
  });
}

/* 播放列表 */
export function playList(data) {
  return request({
    url: API.playList,
    method: 'get',
    params: data,
  });
}

/* 获取章节信息 */
export function getBookCatalogueInfo(data) {
  return request({
    url: API.getBookCatalogueInfo,
    method: 'get',
    params: data,
  });
}

/* 获取资源数量 */
export function getBookCountByType(data) {
  return request({
    url: API.getBookCountByType,
    method: 'get',
    params: data,
  });
}

/* 获取资源数量 */
export function getCountBookApi(data) {
  return request({
    url: API.getCountBook,
    method: 'get',
    params: data,
  });
}

// 点击次数统计
export function savePaperBookLink(data) {
  return request({
    url: '/dev-api/webApi/book/savePaperBookLink',
    method: 'get',
    params: data,
  });
}

