
<div>
  <div class="max1200 m-auto flexRowS pl19 topBreakNav">
    <breadcrumb-pager :breadList="$attrs.bradcard" />
  </div>
  <div class="max1200 m-auto flexRowB itemsSS layoutContainer">
    <div class="flexGrow0 leftQueryContainer">
      <template v-if="$attrs.filter">
        <div class="flexGrow0 leftQueryContainer">
          <div class="searchCondition">
            <div class="search-label">
              <span>上架时间</span>
              <img class="search-icon" src="@/assets/base/time-search-icon.png" alt="" />
            </div>
            <Checkbox
              v-model="ListingVal"
              :isRadio="true"
              class="selectBox"
              :dataList="ListingTime"
            >
              <template #custom>
                <div class="flexRow inputContainer mt9" @click.stop="">
                  <input
                    :disabled="!ListingVal.length == 0"
                    type="text"
                    v-model="startTime"
                    class="inputText pl8"
                  />
                  <!-- @blur="updateTime(1)" -->
                  <!-- @keyup.enter="changeRange" -->
                  <div class="inputLabel mx8">~</div>
                  <input
                    :disabled="!ListingVal.length == 0"
                    type="text"
                    v-model="endTime"
                    class="inputText pl8"
                  />
                  <!-- @keyup.enter="changeRange" -->
                  <button class="flexRow inputBtn ml8" @click="changeRange(1)">
                    <img src="@/assets/images/icons/enter.png" />
                  </button>
                </div>
              </template>
            </Checkbox>
            <div class="search-label mt32">
              <span>出版时间</span>
              <img class="search-icon" src="@/assets/base/time-search-icon.png" alt="" />
            </div>
            <Checkbox
              v-model="publishDate"
              :isRadio="true"
              class="selectBox"
              :dataList="publishTime"
            >
              <template #custom>
                <div class="flexRow inputContainer mt9" @click.stop="">
                  <input
                    :disabled="!publishDate.length == 0"
                    type="text"
                    v-model="publishStartTime"
                    class="inputText pl8"
                    @blur="updateTime(2)"
                  />
                  <!-- @keyup.enter="changeRange" -->
                  <div class="inputLabel mx8">~</div>
                  <input
                    :disabled="!publishDate.length == 0"
                    type="text"
                    v-model="publishEndTime"
                    class="inputText pl8"
                    @blur="updateTime(2)"
                  />
                  <!-- @keyup.enter="changeRange" -->
                  <button class="flexRow inputBtn ml8" @click="changeRange(2)">
                    <img src="@/assets/images/icons/enter.png" />
                  </button>
                </div>
              </template>
            </Checkbox>
          </div>
        </div>
      </template>
      <!-- 基础筛选条件 -->
      <slot name="leftQueryContainer" :type="checkedKey" />
    </div>
    <div class="flexGrow1 queryBody pl11">
      <div class="rightContainer">
        <slot name="topcondition"></slot>
        <slot name="topBlock" />
        <div class="baseDataView">
          <template v-if="$attrs.countmap">
            <div class="flexRowS bookTypeListContainer">
              <div
                v-for="bookType in $attrs.checkboxconfig.list"
                :key="bookType.id"
                class="flexRow cursor bookTypeItem mr8"
                :class="[bookType.id === checkedKey ? 'bookTypeItemActive' : '']"
                v-show="
                  $attrs.countmap[bookType.countProp] > 0 &&
                  (!$route.query.categoryId || bookType.id != '1')
                "
                @click="
                  searchType = 'by hand'
                  checkedKey = bookType.id
                "
              >
                {{ bookType.name }} ({{
                  ($attrs.countmap && $attrs.countmap[bookType.countProp]) || 0
                }})
              </div>
            </div>
          </template>
          <template v-if="$attrs.order">
            <div class="flexRowB orderCondition">
              <div class="flexRow">
                <template v-if="!$attrs.hideorder">
                  <filterUpDown
                    class="mr30"
                    v-model="timeOrder"
                    @reload="orderBy = 0"
                    ref="timeOrder"
                  >
                    上架时间
                  </filterUpDown>
                  <filterUpDown
                    class="mr30"
                    v-model="countOrder"
                    @reload="orderBy = 1"
                    ref="countOrder"
                  >
                    出版时间
                  </filterUpDown>
                  <filterUpDown
                    class="mr30"
                    v-model="hotOrder"
                    @reload="orderBy = 2"
                    ref="hotOrder"
                  >
                    阅读量
                  </filterUpDown>
                </template>
              </div>

              <div class="flexRow displayType">
                <div
                  class="cursor displayBlock"
                  :class="[displayType === 'block' ? 'displayBlockActive' : 'displayBlock']"
                  @click="changeDisplayType('block')"
                  title="大图展示"
                />
                <div
                  class="cursor ml17 displayList"
                  :class="[displayType === 'list' ? 'displayListActive' : 'displayList']"
                  @click="changeDisplayType('list')"
                  title="列表展示"
                />
              </div>
            </div>
          </template>
          <slot name="dataView" :display="displayType" />
          <slot name="bottomBlock" />
        </div>
      </div>
    </div>
  </div>
</div>
