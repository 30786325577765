const api = {
  login: '/dev-api/webApi/login',
  updateUserInfo: '/dev-api/webApi/userInfo/updateUserInfo',
  getUserInfo: '/dev-api/webApi/userInfo/getUserInfo',
  logout: '/dev-api/webApi/logout',
  ssoLogout: 'https://hep-center.u2.hep.com.cn/logout' /* sso退出登录 */,
  specialList: '/dev-api/webApi/special/specialList',
  specailSearch: '/dev-api/webApi/special/tSpecialSubjectSearchList',
  adList: '/dev-api/webApi/ad/adList',
  userLike: '/dev-api/webApi/special/userLike',
  defaultSearchText: '/dev-api/webApi/platformConfig/getPlatformSearchKey',
  fpglOpen: '/dev-api/webApi/invoice/fpglOpen',
  appConfig:'/dev-api/website/websiteConfig/list'
};

export default api;
