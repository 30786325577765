
<div class="listPageContainer">
  <!-- <div
    class="flexRowS pageLabel"
    :class="[!$attrs.tabslist || $attrs.tabslist.length === 0 ? 'mb31' : '']"
  >
    {{ $attrs.title }}
  </div> -->
  <div class="blockContainer settingPage">
    <div class="detailListContainer">
      <template v-if="$attrs.tabslist && $attrs.tabslist.length > 0">
        <div class="flexRowB topSearchContainerWrap">
          <template v-if="!$attrs.select">
            <div class="flexRowS bookTypeListContainer">
              <div
                v-for="bookType in $attrs.tabslist"
                :key="bookType.id"
                class="flexRow cursor bookTypeItem mr25"
                :class="[bookType.id === active ? 'bookTypeItemActive' : '']"
                @click="changeBookType(bookType.id)"
              >
                {{ getTitle(bookType) }}
              </div>
            </div>
          </template>
          <template v-else>
            <div class="flexRowS selectInputWrap">
              <select v-model="selectactive" class="cursor selectInput">
                <option v-for="item in $attrs.tabslist" :key="item.id" :value="item.id">
                  {{ item.name }}
                </option>
              </select>
              <div class="selectRightIcon" />
            </div>
          </template>
          <div v-if="$attrs.search" class="flexRow">
            <div class="flexRowSB cursor pl35 pr24 searchContainer" style="position: relative">
              <span class="searchInputWrap">
                <input
                  v-model="keyWords"
                  @keyup.enter="searchByHandler"
                  placeholder="请输入搜索内容"
                />
              </span>
              <div class="searchIconNav" @click="searchByHandler">
                <img src="@/assets/index/sousuo@2x.png" alt />
              </div>
            </div>
          </div>
          <slot name="ext" />
        </div>
      </template>
      <div class="flexRowB topSearchContainerWrap mb20" v-if="$attrs.onlysearch">
        <div></div>
        <div v-if="$attrs.search" class="flexRow">
          <div class="flexRowSB cursor pl35 pr24 searchContainer" style="position: relative">
            <span class="searchInputWrap">
              <input
                v-model="onSearchKeyWords"
                @keyup.enter="searchByHandler"
                placeholder="请输入搜索内容"
              />
            </span>
            <div class="searchIconNav" @click="searchByHandler">
              <img src="@/assets/index/sousuo@2x.png" alt />
            </div>
          </div>
        </div>
      </div>

      <div class="flexRowSS listWrapContainer px16">
        <template v-if="$attrs.listItem && $attrs.listItem.length">
          <template v-for="(item, index) in $attrs.listItem" :key="item.resourceId">
            <slot name="renderItem" :item="item" :index="index" :type="active" />
          </template>
        </template>
        <template v-else-if="list && list.length > 0">
          <template v-for="(item, index) in list" :key="item.resourceId || item.id || item.Id">
            <slot name="renderItem" :item="item" :index="index" :type="active" />
          </template>
        </template>
        <template v-else>
          <div class="emptyContainer">
            <img src="@/assets/base/empty@2x.png" alt />
            <div class="messageText">很抱歉，暂未找到相关内容</div>
          </div>
        </template>
      </div>
      <div class="pagerInfoContainer mt20" v-if="queryInfo.total > 0 && queryInfo.total">
        <!-- 分页组件 -->
        <MyPager
          ref="MyPager"
          @currentPage="changePageNum"
          :config="{
            total: queryInfo.total,
            pageSize: queryInfo.pageSize,
            pageNum: queryInfo.pageNum,
            pages: queryInfo.pages,
          }"
        ></MyPager>
      </div>
    </div>
  </div>
</div>
