<template>
  <div
    class="cursor bookContainer"
    v-if="$attrs.book"
    :key="$attrs.book.Id || $attrs.book.id"
    @click="goDetail($attrs.book)"
    :class="[
      $attrs.flexRow || $attrs.flexrow ? 'flexRowSB rowLayout' : 'normalLayout',
      $attrs.flexrow ? 'flexRowSB' : '',
      $attrs.shadow ? 'shadow' : '',
      $attrs.noPadding ? 'noPadding' : '',
      $attrs.largePadding ? 'largePadding' : '',
      $attrs.flexClass,
    ]"
    :style="$attrs.style || {}"
  >
    <div
      class="flexGrow0 leftBookFace"
      :key="$attrs.book.Id || $attrs.book.id"
      :style="{
        width: $attrs.width,
        height: $attrs.height,
        background: `url(${
          ($attrs.book && $attrs.book.resourceImg) ||
          $attrs.book.CoverUrl ||
          $attrs.book.img ||
          $attrs.url ||
          $attrs.book.subImg ||
          $store.state.appConfig.ERR_IMG
        }) center center no-repeat`,
        backgroundSize: $attrs.bgsize || 'cover',
      }"
    >
      <div v-if="$attrs.book.exchangeType == 1" class="duiIcon"></div>
      <div v-if="$attrs.book.exchangeType == 2" class="quanIcon"></div>
      <div v-if="$attrs.showVideoIcon" class="videoIcon"></div>
      <div
        class="deleteIcon"
        v-if="$attrs.showdelete"
        @click.stop="deleteBook($attrs.book.id)"
      ></div>
      <div v-if="!$attrs.hidetype" class="flexRow bookTypeName">
        {{
          ($attrs.book && bookTypeNameMap[$attrs.book.ObjectType]) ||
          ($attrs.book && bookTypeNameMap[$attrs.book.objectType]) ||
          ($attrs.book && bookTypeNameMap[$attrs.book.resourceType]) ||
          ""
        }}
      </div>
    </div>
    <div
      class="flexGrow1 rightBootDetail"
      :class="[$attrs.rightFlex ? 'rightFlexRow' : '']"
    >
      <div
        class="topFixHeightContainer"
        :class="[
          $attrs.namePadding ? 'px18' : '',
          $attrs.resetNamePadding,
          $attrs.vertical ? 'contentVertical' : '',
        ]"
      >
        <slot name="bookName"></slot>
        <div
          v-if="!$attrs.hidename"
          class="bookName maxLine1"
          :class="[
            $attrs.titleline1 ? 'bookName1' : '',
            $attrs.titleline2 ? 'bookName2' : '',
            $attrs.titleline2small ? 'bookNameSmall' : '',
            $attrs.titlelinemax ? 'bookNameMax' : '',
            $attrs.nameCenter ? 'flexRow' : '',
          ]"
          v-text="
            $attrs.book
              ? $attrs.book.catalogueName ||
                $attrs.book.resourceName ||
                $attrs.book.bookName ||
                $attrs.book.Title ||
                $attrs.book.title ||
                $attrs.book.subTitle
              : ''
          "
        ></div>
        <!--    章节名称    -->
        <slot name="chapterName"></slot>
        <div>
          <!-- 作者 -->
          <div
            class="bookAuthor"
            :class="[$attrs.authCenter ? 'flexRow' : '']"
            v-if="!$attrs.hideauth"
          >
            <template v-if="$attrs.showauthlabel">作者：</template>
            <template
              v-if="$attrs.book.ObjectType == '101' || $attrs.book.objectType"
            >
              <template v-if="$attrs.book.bookEditorInChief">
                主编：{{ $attrs.book.bookEditorInChief }}
              </template>
            </template>
            <template v-else>
              {{ $attrs.book && $attrs.book.Author }}
            </template>
            <template v-if="$attrs.showZhu">&ensp;著</template>
          </div>
          <template v-if="$attrs.showDetails">
            <div class="bookAuthor" v-if="$attrs.book && $attrs.book.Publisher">
              {{ $attrs.book.Publisher }}
            </div>
            <div class="bookAuthor">
              <template v-if="$attrs.monthCn">
                {{ $attrs.book && $attrs.book.PublishDate | monthCn }}
              </template>
              <template v-else>
                {{ $attrs.book && $attrs.book.IssueDate | dateStr }}
              </template>
            </div>
          </template>
        </div>
        <!-- 课程内容 -->
        <template v-if="$attrs.showCourse">
          <div class="flexRowB bookAuthor timeBookAuthor">
            <span class="time">
              时长：{{ convertSecondsToHMS($attrs.book.WordCountOrDuration) }}
            </span>
            <span>课时：{{ $attrs.book && $attrs.book.NumberChapter }}</span>
          </div>
        </template>
        <!-- 专题 -->
        <template v-if="$attrs.seeCount">
          <div class="bookAuthorCb">
            {{ $attrs.book && $attrs.book.publisher }}
          </div>
          <div class="flexRowB bookAuthor" style="color: #666">
            <span class="time">
              {{ $attrs.book && $attrs.book.publishDate | dateStr }}
            </span>
            <span class="bookAuthorIcon">
              <img src="@/assets/images/icons/eye.png" />
              {{ $attrs.book && $attrs.book.readNum }}
            </span>
          </div>
        </template>
      </div>
      <div class="bottomFlexContainer" :class="[$attrs.bottomdetailstyle]">
        <template v-if="$attrs.showprice">
          <book-discount :book="$attrs.book"></book-discount>
        </template>
        <slot name="bottomDetailText" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { BookTypeDetail } from "@/config/baseConfig";
import { Toast } from "vant";
import { specialJump } from "@/utils/jumpUtil";
export default {
  name: "BookItem",
  props: {},
  data() {
    return {
      bookTypeNameMap: {
        53: "电子书",
        101: "数字教材",
        54: "有声书",
        55: "课程",
        64: "章节",
        1: "专题",
        2: "专题",
        3: "专题",
        ebook: 53,
        dbook: 101,
        sbook: 54,
        course: 55,
        other: 64,
        special: 0,
      },
    };
  },
  created() {},
  methods: {
    goPlayer() {
      this.$router.push("/read?id=" + this.$attrs.book.id);
    },
    goPage(url) {
      if (!url) return;
      this.$router.push(url);
    },
    goDetail(item) {
      console.log(">>>>>>>>", item);
      if (item.deleteFlag === "1") {
        this.$toast("本资源已删除,请联系平台客服");
        return;
      }

      if (item.IsOnShelf !== undefined && item.IsOnShelf !== "1") {
        this.$toast("本资源已下架,请联系平台客服");
        return;
      }
      console.log(this.$attrs.objectType, 9999);
      if (this.$attrs.objectType === 1) {
        this.$emit("onAddReading", item);
        return specialJump(item, this);
      }

      /* 图书类跳转 */
      const jumpUrl = BookTypeDetail[item.objectType || item.ObjectType];
      this.goPage(
        `${jumpUrl}?id=${item.bookId || item.resourceId || item.Id || item.id}`
      );
    },
    deleteBook(id) {
      this.$emit("deleteBook", id);
    },
    convertSecondsToHMS(seconds) {
      if (seconds && isNaN(seconds)) return seconds || "";
      var hours = Math.floor(seconds / 3600);
      var minutes = Math.floor((seconds % 3600) / 60);
      var remainingSeconds = seconds % 60;
      return (
        (hours ? hours + "时" : "") +
        (minutes ? minutes + "分" : "") +
        remainingSeconds +
        "秒"
      );
    },
  },
};
</script>
<style lang="less" scoped>
.shadow {
  box-shadow: 0px 2px 24px 0px rgba(196, 214, 214, 0.34);
}
.noPadding {
  padding: 0 !important;
  .leftBookFace {
    width: 100% !important;
    border: 0px solid #f00;
    border-radius: 4px;
  }
  .rightBootDetail {
    overflow: hidden;
    padding-left: 0;
    .bookName {
      max-width: 100% !important;
      min-height: unset !important;
      font-weight: bolder;
    }
  }
}
.bookNameSmall {
  height: 36px;
  line-height: 18px !important;
  white-space: pre-wrap !important;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.bookName1 {
  display: block !important;
  overflow: hidden !important;
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
}
.bookName2 {
  font-size: 18px !important;
  height: 60px;
  line-height: 30px !important;
  white-space: pre-wrap !important;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
// .bookName2 {
//   width: 100%;
//   display: inline-block;
//   height: auto !important;
//   min-height: auto;
//   font-size: 26px;
//   white-space: unset !important;
//   font-family: PingFang SC;
//   font-weight: bolder;
//   color: #374646;
//   overflow: hidden;
//   text-overflow: ellipsis;
//   display: -webkit-box;
//   -webkit-line-clamp: 4;
//   -webkit-box-orient: vertical;
// }

.rightFlexRow {
  flex-grow: 1;
  width: auto !important;
  margin-left: 30px !important;
}

.largePadding {
  padding: 40px 31px !important;
}
.bookContainer {
  background: #ffffff;
  border-radius: 4px;
  align-items: stretch;
  width: 100%;
  &:hover {
    box-shadow: 0px 4px 32px 0px #0000001a;
  }
  .leftBookFace {
    width: 169px;
    height: 220px;
    border-radius: 5px;
    background: #78bab6;
    box-shadow: 0px 3px 7px 0px rgba(184, 204, 206, 0.47);
    border-radius: 5px;
    position: relative;
    flex-shrink: 0;
    flex-grow: 0;
    border: 0px solid #f0a;
    border-radius: 5px;
    .quanIcon {
      position: absolute;
      top: 4px;
      left: 4px;
      background: url("@/assets/base/newQuan.png") left center no-repeat;
      display: inline-block;
      width: 53px;
      height: 42px;
      background-size: contain;
    }
    .duiIcon {
      position: absolute;
      bottom: 4px;
      left: 4px;
      background: url("@/assets/base/newDui.png") left center no-repeat;
      display: inline-block;
      width: 53px;
      height: 42px;
      background-size: contain;
    }
    .bookTypeName {
      background: rgba(0, 0, 0, 0.55);
      border-radius: 5px;
      position: absolute;
      bottom: 4px;
      right: 6px;
      text-align: center;
      color: #fff;
      font-size: 12px;
      min-width: 52px;
      height: 26px;
      padding: 2px 5px;
    }
    .deleteIcon {
      position: absolute;
      left: 10px;
      top: 10px;
      width: 32px;
      height: 32px;
      z-index: 999;
      cursor: pointer;
    }
    .videoIcon {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 44px;
      height: 44px;
      background: url("@/assets/images/icons/play-circle.png") left center
        no-repeat;
      background-size: 44px 44px;
    }
  }
  .rightBootDetail {
    padding-left: 20px;
    border: 0px solid #f0a;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
    overflow: hidden;
    .topFixHeightContainer {
      border: 0px solid rgb(230, 5, 218);
      flex-grow: 0;
      flex-shrink: 0;
    }
    .bottomFlexContainer {
      border: 0 solid #f9a;
      flex-grow: 1;
      flex-shrink: 1;
    }
    .bookName {
      width: 100%;
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: bolder;
    }
    .bookAuthor {
      font-size: 14px !important;
      font-family: Source Han Sans CN;
      font-weight: 400 !important;
      color: #8d9399 !important;
      line-height: 20px !important;
      margin-top: 12px !important;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .timeBookAuthor {
      font-weight: 400 !important;
    }
    .detailText {
      height: 100px;
      font-size: 22px;
      font-weight: 400;
      color: #666666;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      text-indent: 45px;
    }
  }
}
.rowLayout {
  .leftBookFace {
    // width: 169px !important;
    // height: 160px !important;
  }
}
.normalLayout {
  width: 100%;
  .rightBootDetail {
    padding-left: 0;
    margin-top: 10px;
    .bookName {
      // width: 196px;
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: bolder;
      color: #222222;
      margin-bottom: 5px;
      line-height: 22px;
      min-height: auto !important;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .bookAuthor {
      font-size: 12px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #666666;
      line-height: 20px;
      padding: 0;
      margin-top: 0;
    }
  }
}

.bookAuthorCb {
  // margin-top: -5px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.bookAuthorIcon {
  img {
    position: relative;
    top: -1.5px;
    width: 20px;
    margin-right: 2px;
  }
}
</style>
