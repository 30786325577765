import Vue from 'vue'
import { createApp, h } from 'vue-demi'
import VueCompositionApi from '@vue/composition-api'
import { errorImg } from '@/utils/index'

import App from '@/App.vue'

// filters
import './filters';

import 'windi.css'
import router from '@/router'

import "./app.less"
import {isLogin} from '@/utils/login.js'
Vue.prototype.$isLogin = isLogin
import NewBaseQuery from '@/components/NewQueryLayout.vue'
import NewBaseListPager from '@/components/NewBaseListPager.vue'
import BaseQuery from '@/components/QueryLayout.vue'
import BaseListPager from '@/components/BaseListPager.vue'
import NewBookItem from '@/components/NewBookItem.vue'
import NewMyBookItem from '@/components/NewMyBookItem.vue'
import BookDiscount from '@/components/BookDiscount.vue'
import BookDiscountTag from '@/components/BookDiscountTag.vue'


import Vant from 'vant';
import 'vant/lib/index.css';

import '@vant/touch-emulator';
Vue.use(Vant);
import Poster from '@/views/detail/components/Poster.vue';

Vue.component('book-poster', Poster);
Vue.component('new-base-query', NewBaseQuery)
Vue.component('new-list-pager', NewBaseListPager)
Vue.component('new-book-item', NewBookItem)
Vue.component('new-my-book-item', NewMyBookItem)
Vue.component('base-query', BaseQuery)
Vue.component('list-pager', BaseListPager)
Vue.component('book-discount', BookDiscount)
Vue.component('book-discount-tag', BookDiscountTag)
Vue.prototype.$errorImg = errorImg
import { store } from '@/store/index'

Vue.use(VueCompositionApi)

Vue.config.productionTip = false
Vue.config.devtools = true

const app = createApp({
  router,
  store,
  render: () => h(App),
})

app.mount('#app')
