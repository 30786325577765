export const API = {
  list:
    '/dev-api/webApi/special/specialList' /* 专题列表  recommendType 推荐管理分类对应recommend_type字典  144近期榜单上方专题 145:近期榜单下方专题 146:专题推荐*/,
  listBySubType:
    '/dev-api/webApi/special/specialListBySubjectType' /* 专题类型查询专题列表 subjectType:0 知识专题 1:活动专题 */,
  subSpecialDetail:
    '/dev-api/webApi/special/specialBookTypeBySubjectId' /* 获取专题绑定的电子书内容类型,当专题的resourceType=2,3时有效返回的类型逗号隔开 */,
  specialListBySubjectId:
    '/dev-api/webApi/special/specialListBySubjectId' /* 获取专题绑定的其他专题列表,当专题的resourceType=1时有效 */,
  specialSearch:
    '/dev-api/webApi/special/tSpecialSubjectSearchList' /* 专题搜索 */,
  specialPushPager:
    '/dev-api/webApi/special/specialListPage' /* 专题推荐分页 */,
  specialInfo: '/dev-api/webApi/special/specialInfoById' /* 查询专题详情信息 */,
};
// axios
import request from '@/utils/request';

import { userRead } from '@/api/bookDetail.js';

/* 书架列表 */
export function specialList(data) {
  return request({
    url: API.list,
    method: 'get',
    params: data,
  });
}

/* 专题推荐分页获取列表 */
export function specialPushPager(data) {
  return request({
    url: API.specialPushPager,
    method: 'get',
    params: data,
  });
}

/* 书架列表 */
export function specialListBySubType(data) {
  return request({
    url: API.listBySubType,
    method: 'get',
    params: data,
  });
}

/* 子专题详情 */
export function subSpecialDetail(data) {
  return request({
    url: API.subSpecialDetail,
    method: 'get',
    params: data,
  });
}

/* 子专题详情内容 不包含 关联内容 */
export function specialInfo(data) {
  userRead({ resourceId: data.id, resourceType: 0 });
  return request({
    url: API.specialInfo,
    method: 'get',
    params: data,
  });
}

/* 子专题列表 */
export function specialListBySubjectId(data) {
  return request({
    url: API.specialListBySubjectId,
    method: 'get',
    params: data,
  });
}
/* 专题列表搜索 */
export function specialSearch(data) {
  return request({
    url: API.specialSearch,
    method: 'get',
    params: data,
  });
}
