import Vue from 'vue';
import axios from 'axios';
import { store } from '@/store';
import { Toast, Dialog } from 'vant';

const catcher = {
  config: {
    time: 30 * 1000,
  },
  data: {},
};

Vue.use(Toast);
Vue.use(Dialog);

let isError = false;
// 根据环境不同引入不同api地址
// create an axios instance

const service = axios.create({
  baseURL: window.__appBaseConfig__.WEB_BASE_API_URL, // url = base api url + request url
  withCredentials: true, // send cookies when cross-domain requests
  timeout: 8000, // request timeout
});

// request拦截器 request interceptor
service.interceptors.request.use(
  (config) => {
    // 不传递默认开启loading
    if (!config.hideloading) {
      // Toast.loading({
      //   forbidClick: true,
      // });
    }
    if (
      store.getters.token &&
      config.url.indexOf('dev-api/webApi/login') === -1
    ) {
      config.headers['Authorization'] = store.getters.token;
    }
    return config;
  },
  (error) => {
    // do something with request error
    console.log(error); // for debug
    return Promise.reject(error);
  }
);
// respone拦截器
service.interceptors.response.use(
  (response) => {
    setTimeout(() => {
      Toast.clear();
    }, 1000)
    const res = response.data;
    /* 免费购买成功返回响应 600 需要排除 */
    if (res.code && res.code !== 200 && res.code !== 600) {
      // 登录超时,重新登录
      if (res.code === 401) {
        if (isError) {
          // return;
        }
        isError = true;
        store.dispatch('savePathInfo', { url: window.location.href });
        Dialog.alert({
          message: res.msg || '登录失效,请重新登录',
          theme: 'round-button',
        }).then(() => {
          store.dispatch("LOGIN", { token: "" }).then(
            (s) => { },
            (e) => {
              console.error(e);
            }
          );
        });
      } else {
        // Toast(res.msg || '登录失效,请重新登录')
        // store.dispatch('savePathInfo', { url: '' })
        // window.location.replace(`${location.origin}/web/#/index`)
        return Promise.reject(res);
      }
    } else {
      // console.log(res);
      return Promise.resolve(res);
    }
  },
  (error) => {
    setTimeout(() => {
      Toast.clear();
    }, 1000)
    console.log('err' + error); // for debug
    return Promise.reject(error);
  }
);

/* 带有缓存的请求 */
export const enhancedService = async (data) => {
  const requestKey = JSON.stringify(data);
  const catchedData = catcher.data[requestKey];
  if (catchedData) {
    if (+new Date() - catchedData.time > catcher.config.time) {
      console.info('缓存失效重新请求新数据');
    } else {
      return catchedData.data;
    }
  }

  const result = await service(data);
  catcher.data[requestKey] = {
    data: result,
    time: +new Date(),
  };
  return result;
};

export default service;
