import { H5BookTypeDetail } from '@/config/baseConfig';
const { WEB_BASE_URL } = window.__appBaseConfig__;

export function getShareUrl(type, id) {
  const url = `${H5BookTypeDetail[type]}?id=${id}&fromShare=true`;
  // console.log('url', url, type, id);
  const baseUrl = `${WEB_BASE_URL}2024h5/#`;
  const openUrl = `${baseUrl}${url}`
  // console.error('openUrl',openUrl)
  return `${openUrl}`;
  // return `${baseUrl}/home?reopen=${baseUrl}${url}`;
}
