
<div class="poasterPopBg">
  <div
    class="flexRow commentContainerPop postImageContainer"
    :style="{
      background: `${$attrs.book.BookPosterUrl ? 'transparent' : '#fff'}`,
    }"
  >
    <div class="cursor closeIconWhite" @click="$emit('closeshare')"></div>
    <div
      id="catImg"
      class="innerContainer"
      style="padding: 0px; background: #fff"
    >
      <template v-if="$attrs.book.BookPosterUrl">
        <div
          class="imgContainer"
          :style="{
            height: $attrs.height ? `${$attrs.height}px !important` : '',
          }"
          style="padding: 0px; background: #fff"
        >
          <!-- <img
            :src="require(`@/assets/test/book-h.png`)"
            style="width: 100%; height: 100%; border: 0px solid #f00"
            alt
          /> -->

          <img
            :src="`${$attrs.book.BookPosterUrl}?v=${Math.ceil(
              Math.random() * 100000
            )}`"
            crossorigin="anonymous"
            :key="`${$attrs.book.BookPosterUrl}`"
            style="width: 100%; height: 100%; border: 0px solid #f00"
            alt
          />
        </div>
      </template>
      <template v-else>
        <div
          class="imgContainer"
          :style="{
            height: $attrs.height ? `${$attrs.height}px !important` : '',
          }"
        >
          <img
            :src="`${
              $attrs.book.BookPosterUrl ||
              $attrs.book.subImg ||
              $attrs.book.CoverUrl
            }?v=${Math.ceil(Math.random() * 100000)}`"
            crossorigin="anonymous"
            :key="`${
              $attrs.book.BookPosterUrl ||
              $attrs.book.subImg ||
              $attrs.book.CoverUrl
            }`"
            style="width: 100%; height: 100%; border: 0px solid #f00"
            alt
          />
        </div>
        <div class="dashLine"></div>
        <div class="shareBottomContainer pb24">
          <div class="qrCode" v-if="$attrs.shareUrl">
            <VueQr :size="70" margin="0" :text="$attrs.shareUrl"></VueQr>
          </div>
          <img :src="shareBottomBg" class="shareBottomContainerImg" alt />
        </div>
      </template>
    </div>
    <div class="cursor flexRow bottomCloseContainer" @click="download">
      <div class="xiazaiIcon"></div>
      <div class="downloadText">下载保存</div>
    </div>
  </div>
</div>
