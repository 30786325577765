/* 搜索 默认布局 包含 面包屑 左侧查询 右侧展示 filter 左侧精简查询 checkboxconfig 配置资源类型*/
<template>
  <div>
    <div class="max1200 m-auto flexRowS pl19 topBreakNav">
      <breadcrumb-pager :breadList="$attrs.bradcard" />
    </div>
    <div class="max1200 m-auto flexRowB itemsSS layoutContainer">
      <div class="flexGrow0 leftQueryContainer">
        <template v-if="$attrs.filter">
          <div class="flexGrow0 leftQueryContainer">
            <div class="searchCondition">
              <div class="search-label">
                <span>上架时间</span>
                <img class="search-icon" src="@/assets/base/time-search-icon.png" alt="" />
              </div>
              <Checkbox
                v-model="ListingVal"
                :isRadio="true"
                class="selectBox"
                :dataList="ListingTime"
              >
                <template #custom>
                  <div class="flexRow inputContainer mt9" @click.stop="">
                    <input
                      :disabled="!ListingVal.length == 0"
                      type="text"
                      v-model="startTime"
                      class="inputText pl8"
                    />
                    <!-- @blur="updateTime(1)" -->
                    <!-- @keyup.enter="changeRange" -->
                    <div class="inputLabel mx8">~</div>
                    <input
                      :disabled="!ListingVal.length == 0"
                      type="text"
                      v-model="endTime"
                      class="inputText pl8"
                    />
                    <!-- @keyup.enter="changeRange" -->
                    <button class="flexRow inputBtn ml8" @click="changeRange(1)">
                      <img src="@/assets/images/icons/enter.png" />
                    </button>
                  </div>
                </template>
              </Checkbox>
              <div class="search-label mt32">
                <span>出版时间</span>
                <img class="search-icon" src="@/assets/base/time-search-icon.png" alt="" />
              </div>
              <Checkbox
                v-model="publishDate"
                :isRadio="true"
                class="selectBox"
                :dataList="publishTime"
              >
                <template #custom>
                  <div class="flexRow inputContainer mt9" @click.stop="">
                    <input
                      :disabled="!publishDate.length == 0"
                      type="text"
                      v-model="publishStartTime"
                      class="inputText pl8"
                      @blur="updateTime(2)"
                    />
                    <!-- @keyup.enter="changeRange" -->
                    <div class="inputLabel mx8">~</div>
                    <input
                      :disabled="!publishDate.length == 0"
                      type="text"
                      v-model="publishEndTime"
                      class="inputText pl8"
                      @blur="updateTime(2)"
                    />
                    <!-- @keyup.enter="changeRange" -->
                    <button class="flexRow inputBtn ml8" @click="changeRange(2)">
                      <img src="@/assets/images/icons/enter.png" />
                    </button>
                  </div>
                </template>
              </Checkbox>
            </div>
          </div>
        </template>
        <!-- 基础筛选条件 -->
        <slot name="leftQueryContainer" :type="checkedKey" />
      </div>
      <div class="flexGrow1 queryBody pl11">
        <div class="rightContainer">
          <slot name="topcondition"></slot>
          <slot name="topBlock" />
          <div class="baseDataView">
            <template v-if="$attrs.countmap">
              <div class="flexRowS bookTypeListContainer">
                <div
                  v-for="bookType in $attrs.checkboxconfig.list"
                  :key="bookType.id"
                  class="flexRow cursor bookTypeItem mr8"
                  :class="[bookType.id === checkedKey ? 'bookTypeItemActive' : '']"
                  v-show="
                    $attrs.countmap[bookType.countProp] > 0 &&
                    (!$route.query.categoryId || bookType.id != '1')
                  "
                  @click="
                    searchType = 'by hand'
                    checkedKey = bookType.id
                  "
                >
                  {{ bookType.name }} ({{
                    ($attrs.countmap && $attrs.countmap[bookType.countProp]) || 0
                  }})
                </div>
              </div>
            </template>
            <template v-if="$attrs.order">
              <div class="flexRowB orderCondition">
                <div class="flexRow">
                  <template v-if="!$attrs.hideorder">
                    <filterUpDown
                      class="mr30"
                      v-model="timeOrder"
                      @reload="orderBy = 0"
                      ref="timeOrder"
                    >
                      上架时间
                    </filterUpDown>
                    <filterUpDown
                      class="mr30"
                      v-model="countOrder"
                      @reload="orderBy = 1"
                      ref="countOrder"
                    >
                      出版时间
                    </filterUpDown>
                    <filterUpDown
                      class="mr30"
                      v-model="hotOrder"
                      @reload="orderBy = 2"
                      ref="hotOrder"
                    >
                      阅读量
                    </filterUpDown>
                  </template>
                </div>

                <div class="flexRow displayType">
                  <div
                    class="cursor displayBlock"
                    :class="[displayType === 'block' ? 'displayBlockActive' : 'displayBlock']"
                    @click="changeDisplayType('block')"
                    title="大图展示"
                  />
                  <div
                    class="cursor ml17 displayList"
                    :class="[displayType === 'list' ? 'displayListActive' : 'displayList']"
                    @click="changeDisplayType('list')"
                    title="列表展示"
                  />
                </div>
              </div>
            </template>
            <slot name="dataView" :display="displayType" />
            <slot name="bottomBlock" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import Vue from "vue"
import { defineComponent } from "@vue/composition-api"

import { Slider, Toast } from "vant"
import { yearRange } from "@/api/bookDetail"

Vue.use(Slider)
Vue.use(Toast)

export default defineComponent({
  components: {},
  setup() {},
  data() {
    let checkbox = ""
    if (this.$attrs.checkboxconfig) {
      const defaultCheckedList = (this.$attrs.checkboxconfig.list || []).filter(i => i.checked)
      if (defaultCheckedList.length > 0) {
        checkbox = defaultCheckedList[0].id
      }
    }

    return {
      currentDate: new Date().getFullYear(),
      searchType: "",
      // 上架时间
      ListingVal: ["all"],
      ListingTime: [
        { label: "全部", value: "all" },
        { label: "2024年", value: "2024" },
        { label: "2023年", value: "2023" },
        { label: "2022年", value: "2022" },
        { label: "2021年", value: "2021" },
        { label: "2020年", value: "2020" },
      ],
      // 出版时间
      publishDate: ["all"],
      publishTime: [
        { label: "全部", value: "all" },
        { label: "2024年", value: "2024" },
        { label: "2023年", value: "2023" },
        { label: "2022年", value: "2022" },
        { label: "2021年", value: "2021" },
        { label: "2020年", value: "2020" },
      ],
      publishValue: [],
      cateGoryId: this.$route.query.categoryId,
      orderBy: 0,
      checkedKey: checkbox,
      startTime: "",
      endTime: "",
      // 出版时间
      publishStartTime: "",
      publishEndTime: "",
      timeRangeStart: "",
      timeRangeEnd: "",
      value: [],
      // value: [this.$attrs.startyear, this.$attrs.endyear],
      displayType: "block",
      timeOrder: false,
      hotOrder: "",
      countOrder: "",
      research: false,
    }
  },
  computed: {
    orderStatus() {
      let checkbox = ""
      if (this.$attrs.checkboxconfig && !this.$attrs.checkboxconfig.yearRange) {
        checkbox =
          this.$attrs.checkboxconfig.type === "checkbox"
            ? JSON.stringify(this.$attrs.checkboxconfig.list || [])
            : this.checkedKey
      }
      return (
        this.timeOrder +
        "_" +
        this.hotOrder +
        "_" +
        this.countOrder +
        "_" +
        this.research +
        "_" +
        checkbox +
        "_" +
        JSON.stringify(this.value) +
        "_" +
        this.ListingVal +
        "_" +
        this.publishDate +
        "_" +
        JSON.stringify(this.publishValue) +
        "_" +
        this.startTime +
        "_" +
        this.endTime //+ '_' + this.value[0] + "_" + this.value[1]
      )
    },
  },
  created() {
    // this.value = ['','']
    // this.startTime = this.timeRangeStart
    // this.endTime = this.timeRangeEnd
    // this.$watch("$attrs.checkboxconfig", () => {
    //   if (this.$attrs.checkboxconfig) {
    //     const defaultCheckedList = (this.$attrs.checkboxconfig.list || []).filter(i => i.checked)
    //     console.error('defaultCheckedList', defaultCheckedList)
    //     if (defaultCheckedList.length > 0) {
    //       this.checkedKey = defaultCheckedList[0].id
    //     }
    //   }
    // }, { immediate: true })
    this.$watch(
      "$route.query",
      () => {
        this.searchType = ""
      },
      { immediate: true }
    )
    this.$watch(
      "ListingVal",
      val => {
        console.log(val, "------------------222")
        if (val.length > 0) {
          if (val[0] == "all") {
            this.value[0] = [""]
            this.value[1] = ""
          } else {
            this.value[0] = val[0]
            this.value[1] = ""
          }
        }
      },
      { immediate: true }
    )
    this.$watch(
      "publishDate",
      val => {
        if (val.length > 0) {
          if (val[0] == "all") {
            this.publishValue[0] = [""]
            this.publishValue[1] = ""
          } else {
            this.publishValue[0] = val[0]
            this.publishValue[1] = ""
          }
        }
      },
      { immediate: true }
    )
    this.$watch(
      "$attrs.defaultkey",
      () => {
        if (this.$attrs.defaultkey) {
          this.checkedKey = this.$attrs.defaultkey
        }
      },
      { immediate: true }
    )

    this.$watch(
      "checkedKey",
      async newValue => {
        this.$emit("changNav", this.checkedKey)

        if (this.$attrs.checkboxconfig && this.$attrs.checkboxconfig.yearRange) {
          // const [start, end] = await this.getYearRange(newValue)
          // this.timeRangeStart = start
          // this.startTime = start
          // this.value[0] = start

          // this.timeRangeEnd = end
          // this.endTime = end
          // this.value[1] = end
          this.research = !this.research
        }
      },
      { immediate: true }
    )

    this.$watch(
      "$attrs.ObjectType",
      async newValue => {
        if (this.$attrs.ObjectType !== undefined) {
          // const [start, end] = await this.getYearRange(newValue)
          // this.timeRangeStart = start
          // this.startTime = start
          // this.value[0] = start

          // this.timeRangeEnd = end
          // this.endTime = end
          // this.value[1] = end
          this.research = !this.research
        }
      },
      { immediate: true }
    )

    this.$watch("$attrs.startyear", start => {
      this.timeRangeStart = start
      this.startTime = start
      this.value[0] = start
    })
    this.$watch("$attrs.endyear", end => {
      this.timeRangeEnd = end
      this.endTime = end
      this.value[1] = end
    })

    this.$watch(
      "orderStatus",
      (newValue, oldValue) => {
        // console.error('newValue', newValue)
        let checkbox = ""
        if (this.$attrs.checkboxconfig) {
          checkbox =
            this.$attrs.checkboxconfig.type === "checkbox"
              ? (this.$attrs.checkboxconfig.list || [])
                  .filter(i => i.checked)
                  .map(i => i.id)
                  .join(",")
              : this.checkedKey
        }
        const timeOrder = this.timeOrder
        const hotOrder = this.hotOrder
        const countOrder = this.countOrder
        let sortInfo = {}
        if (this.orderBy === 0) {
          sortInfo.OnShelfDateisAsc = timeOrder
          this.$nextTick(() => {
            this.$refs.countOrder.reset()
            this.$refs.hotOrder.reset()
          })
        } else if (this.orderBy === 1) {
          sortInfo.IssueDateAsc = countOrder
          this.$nextTick(() => {
            this.$refs.timeOrder.reset()
            this.$refs.hotOrder.reset()
          })
        } else if (this.orderBy === 2) {
          sortInfo.clickThroughRateisAsc = hotOrder
          this.$nextTick(() => {
            this.$refs.timeOrder.reset()
            this.$refs.countOrder.reset()
          })
        }
        console.log(timeOrder, hotOrder, countOrder, "countOrdercountOrder")

        const otherInfo = {}
        if (this.$attrs.ObjectType !== undefined) {
          otherInfo["ObjectType"] = this.$attrs.ObjectType
        }
        // console.log( {
        //   ...sortInfo,
        //   ...otherInfo,
        //   startYear: this.value[0],
        //   endYear: this.value[1],
        //   checkedIds: checkbox,
        // },'22');
        // 时间判断
        let publishDate = []
        if (this.publishValue.length > 0) {
          if (this.publishValue[0] && this.publishValue[1]) {
            const sjTime = []
            for (let i = this.publishValue[0]; i <= this.publishValue[1]; i++) {
              sjTime.push(i)
            }
            publishDate = sjTime
          } else {
            publishDate = [this.publishValue[0] || this.publishValue[1]]
          }
        }
        console.log(this.publishValue, publishDate, "publishDatepublishDate")

        this.$emit("orderchange", {
          ...sortInfo,
          ...otherInfo,
          cateGoryId: this.cateGoryId,
          startYear: this.value[0],
          endYear: this.value[1] || this.value[0],
          publishDate: publishDate.join(","),
          // publishStartTime: this.publishValue[0],
          // publishEndTime: this.publishValue[1],
          checkedIds: checkbox,
        })
      },
      {
        immediate: true,
      }
    )

    this.$watch(
      "searchType",
      (newValue, oldValue) => {
        console.log(newValue, oldValue, "newValue, oldValue")
        this.$emit("searchtypechange", newValue)
      },
      {
        immediate: true,
      }
    )
  },
  methods: {
    async getYearRange(bookType) {
      const { code, msg, data } = await yearRange({ ObjectType: bookType })
      if (code !== 200) {
        Toast(msg)
        return ["", ""]
      }
      return [data.endYear, data.startYear]
    },
    updateTime() {
      // const startTime = this.startTime
      // const endTime = this.endTime
      // if (startTime * 1 < this.timeRangeStart) {
      //   this.startTime = this.timeRangeStart
      // }
      // if (startTime * 1 > this.value[1]) {
      //   this.startTime = this.value[1]
      // }
      // if (endTime * 1 > this.timeRangeEnd) {
      //   this.endTime = this.timeRangeEnd
      // }
      // if (endTime * 1 < this.value[0]) {
      //   this.endTime = this.value[0]
      // }
      // this.value = [this.startTime, this.endTime]
    },
    changeRange(type) {
      const { startTime, endTime, publishStartTime, publishEndTime, currentDate } = this

      if (type == 1) {
        if (
          (startTime && (startTime.length !== 4 || startTime > currentDate)) ||
          (endTime && (endTime.length !== 4 || endTime > currentDate))
        ) {
          return Toast("请输入有效上架时间")
        }
        this.value = [this.startTime, this.endTime]
      } else {
        if (
          (publishStartTime && (publishStartTime.length !== 4 || publishStartTime > currentDate)) ||
          (publishEndTime && (publishEndTime.length !== 4 || publishEndTime > currentDate))
        ) {
          return Toast("请输入有效上架时间")
        }
        if (publishStartTime && publishEndTime) {
          if (publishStartTime > publishEndTime) {
            Toast("上架时间开始时间不能早于结束时间")
            return
          }
        }
        this.publishValue = [this.publishStartTime, this.publishEndTime]
        console.log(this.publishValue, "----this.publishValue--------------")
      }
      this.research = !this.research
    },
    changeDisplayType(type: string) {
      this.displayType = type
    },
    onChange(value) {
      // console.log('当前值：' + value);
    },
    updateCheckedKey(id) {
      this.checkedKey = id
    },
  },
})
</script>
<style lang="less" scoped>
.bookTypeListContainer {
  padding: 23px 8px 31px 22px;
  .bookTypeItem {
    width: 90px;
    height: 34px;
    color: #999;
    background: #fff;
    border: 1px solid #d9d9d9;
    border-radius: 19px;
    font-size: 14px;
    font-family: Source Han Sans CN;
    font-weight: 500;

    font-size: 12px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #999;
  }

  .bookTypeItemActive {
    background: #1890ff;
    box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.04);
    border: 1px solid #ffffff00;
    color: #ffffff;
  }
}
.layoutContainer {
  width: 1200px;
  height: 100%;
  overflow-x: hidden;
  border: 0px solid #f00;
}
.searchLabel {
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #222222;
}
.typeLabel {
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #222222;
}
.leftQueryContainer {
  width: 257px;
  min-height: 234px;
  box-shadow: 0px 0px 21px 0px rgba(234, 234, 234, 0.35);
  .inputContainer {
    font-size: 12px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #666666;
  }
  .checkboxIcon,
  .checkboxCheckedIcon {
    width: 14px;
    height: 14px;
  }

  .leftQueryContainer {
    .label {
      font-family: YouSheBiaoTiHei;
      font-size: 16px;
      font-weight: 400;
      line-height: 22px;
      text-align: left;
    }
    .search-label {
      display: flex;
      align-items: center;
      font-weight: bold;
      font-size: 14px;
      color: #222222;
      .search-icon {
        width: 16px;
        height: 16px;
        margin-left: 12px;
      }
    }

    .searchCondition {
      min-height: 234px;
      background: #ffffff;
      box-shadow: 0px 0px 21px 0px rgba(234, 234, 234, 0.35);
      padding: 20px;
    }
    ::v-deep .selectBox {
      display: flex;
      flex-direction: column;
      margin-top: 16px;
      .checkBoxWarp {
        display: flex;
        flex-direction: column;
        align-items: baseline;
        .checkItem {
          margin-bottom: 12px;
        }
      }
      .customBox {
        display: flex;
        flex-direction: column;
        align-items: baseline;
      }
      .inputContainer {
      }
      .inputText {
        width: 73px;
        height: 24px;
        background: #ffffff;
        border-radius: 2px 2px 2px 2px;
        border: 1px solid #d9d9d9;
        font-size: 14px;
        color: #666;
      }
      .inputBtn {
        border: 1px solid #d9d9d9;
        padding: 6px 7px;
        img {
          width: 11px;
          height: 11px;
        }
      }
    }
    .uptime {
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #222222;
    }

    .inputContainer {
      font-size: 12px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #666666;
    }
    .sliderContainer {
      margin-left: auto;
      margin-right: auto;
      width: 180px;
      height: 26px;
      position: relative;
      .sliderBg,
      .sliderActive {
        background: #f0f;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        .year {
          position: absolute;
          top: -20px;
          text-align: center;
          font-size: 12px;
          font-family: Source Han Sans CN;
          font-weight: 500;
          color: #088190;
        }
        .startItem {
          transform: translateX(-13px) translateY(-13px);
          position: relative;
        }
        .endItem {
          transform: translateX(-13px) translateY(-13px);
          position: relative;
          .year {
            position: absolute;
            bottom: -20px !important;
            top: auto;
          }
        }

        .bgLine {
          height: 4px;
          background: #eeeeee;
          border-radius: 2px;
        }
        .bgLineActive {
          width: 91px;
          height: 4px;
          background: #088190;
          border-radius: 2px;
        }
      }
    }

    .hotPush {
      background: #ffffff;
      box-shadow: 0px 0px 21px 0px rgba(234, 234, 234, 0.35);
      .seqContainer {
        display: inline-block;
        width: 14px;
        height: 15px;
        border: 0px solid #f0a;
        text-align: center;

        line-height: 15px;
        font-size: 12px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #acacac;
      }
      .hotItem {
        &:nth-child(2) {
          .seqContainer {
            background: url("@/assets/base/top1@2x.png") center center no-repeat;
            background-size: contain;
            color: #fff;
          }
        }
        &:nth-child(3) {
          .seqContainer {
            background: url("@/assets/base/top2@2x.png") center center no-repeat;
            background-size: contain;
            color: #fff;
          }
        }
        &:nth-child(4) {
          .seqContainer {
            background: url("@/assets/base/top3@2x.png") center center no-repeat;
            background-size: contain;
            color: #fff;
          }
        }
      }
    }
  }
}
.queryBody {
  max-width: 100%;
  overflow: auto;
  .rightContainer {
    width: 925px;
    min-height: 100%;

    .topBreakNav {
      height: 49px;
      background: #ffffff;
      box-shadow: 0px 0px 21px 0px rgba(234, 234, 234, 0.35);

      font-size: 12px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #999999;

      .last {
        color: #088190;
      }
    }
    .baseDataView {
      min-height: 100%;
      background: #fff;
      box-shadow: 0px 0px 21px 0px rgba(234, 234, 234, 0.35);
      .orderCondition {
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #666666;
        padding: 0 8px 0px 22px;
      }
      .bookList {
        .listBookItem {
          width: 196px;
          border: 0px solid #f0a;
          overflow: hidden;
          &:nth-child(4n) {
            margin-right: 0 !important;
          }
        }
        .listBookItemRow {
          border: 0px solid #00f;
          .rowTextDetail {
            height: 91px;
            font-size: 12px;
            font-family: Source Han Sans CN;
            font-weight: 500;
            color: #666666;
            line-height: 26px;
          }
        }
      }
    }
  }
}
</style>
