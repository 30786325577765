
<div class="max1200 m-auto">
  <breadcrumb-pager :breadList="[$attrs.title]" />
  <template v-if="$slots.headContainer">
    <slot name="headContainer" />
  </template>
  <div class="flexRowB itemsSS layoutContainer">
    <div
      class="flexGrow0 leftQueryContainer mr10"
      v-if="$slots.leftContainer"
    >
      <slot name="leftContainer" />
    </div>
    <div class="flexGrow1">
      <div class="topCondition mb10 bgw" v-if="$attrs.filter">
        <div class="pt20 px20 pb15">
          <ul :class="{ more: isMore }">
            <!-- <li class="flexRowBS mb16" v-if="$attrs.objectType === 1">
              <span class="label-name mr35 blue">专题类别</span>
              <Checkbox
                v-model="ListTypeVal"
                :all="true"
                :isLeftAlignment="true"
                :judgingWidth="true"
                :contentMaxWidth="996"
                :dataList="ListType"
              >
              </Checkbox>
            </li> -->
            <!-- <li class="flexRowS">
              <span class="label-name mr16">上架时间：</span>
              <Checkbox
                v-model="ListingVal"
                :all="true"
                :dataList="ListingTime"
                @customChange="customChange($event, 1)"
              >
                <template #custom>
                  <div class="flexRow inputContainer" @click.stop="">
                    <input
                      type="text"
                      ref="startTime"
                      v-model="startTime"
                      class="inputText"
                      @blur="updateTime(1)"
                      @keyup.enter="changeRange(1)"
                    />
                    <div class="inputLabel mx8">~</div>
                    <input
                      type="text"
                      ref="endTime"
                      v-model="endTime"
                      class="inputText"
                      @blur="updateTime(1)"
                      @keyup.enter="changeRange(1)"
                    />
                    <button
                      class="flexRow inputBtn ml8"
                      @click="changeRange(1)"
                    >
                      <img src="@/assets/images/icons/enterBtn.png" />
                    </button>
                  </div>
                </template>
              </Checkbox>
            </li> -->
            <!-- mt16 -->
            <li class="flexRowBS">
              <span class="label-name mr35 blue">出版时间</span>
              <Checkbox
                v-model="PublicationVal"
                :all="true"
                :isLeftAlignment="true"
                :judgingWidth="true"
                :dataList="PublicationTime"
                @customChange="customChange($event, 2)"
              >
                <template #custom>
                  <div class="flexRow inputContainer">
                    <input
                      type="text"
                      ref="pubstartTime"
                      v-model="pubstartTime"
                      class="inputText"
                      @blur="updateTime(2)"
                      @keyup.enter="changeRange(2)"
                    />
                    <div class="inputLabel mx8">-</div>
                    <input
                      type="text"
                      ref="pubendTime"
                      v-model="pubendTime"
                      class="inputText"
                      @blur="updateTime(2)"
                      @keyup.enter="changeRange(2)"
                    />
                    <button
                      class="flexRow inputBtn ml8"
                      @click="changeRange(2)"
                    >
                      <img src="@/assets/images/icons/enterBtn.png" />
                    </button>
                  </div>
                </template>
              </Checkbox>
            </li>
            <li class="flexRowBS mt8" v-if="$attrs.objectType !== 1">
              <span class="label-name mr35 purple" style="margin-top: -1px"
                >分&emsp;&emsp;类</span
              >
              <Checkbox
                :all="true"
                :isLeftAlignment="true"
                :judgingWidth="true"
                v-model="typeVal"
                :more="typeData && typeData.length > 4"
                :dataList="typeData"
              />
            </li>
            <li class="flexRowBS mt8">
              <span class="label-name mr35 green" style="margin-top: -1px"
                >出版单位</span
              >
              <Checkbox
                :all="true"
                :isLeftAlignment="true"
                :judgingWidth="true"
                :contentMaxWidth="$attrs.objectType === 1 ? 996 : 702"
                v-model="unitVal"
                :more="true"
                :dataList="unitList"
              />
            </li>
          </ul>
          <div
            class="flexRow moreBtn mt20 cursor"
            :class="{ more: isMore }"
            @click="bindSee"
          >
            <img src="@/assets/images/icons/arrow-t.png" class="mr4" />{{
              isMore ? "收起" : "展开"
            }}
          </div>
        </div>
      </div>

      <div class="contentCondition bgw">
        <template
          v-if="
            $attrs.countmap &&
            $attrs.checkboxconfig &&
            $attrs.checkboxconfig.list.length > 0
          "
        >
          <div class="flexRowS bookTypeListContainer pt20 px20">
            <div
              v-for="bookType in $attrs.checkboxconfig.list"
              :key="bookType.id"
              class="flexRow cursor bookTypeItem mr16"
              :class="[
                bookType.id === checkedKey ? 'bookTypeItemActive' : '',
              ]"
              @click="
                searchType = 'by hand';
                checkedKey = bookType.id;
              "
            >
              {{ bookType.name }} ({{
                ($attrs.countmap && $attrs.countmap[bookType.countProp]) || 0
              }})
            </div>
          </div>
        </template>
        <template v-if="$attrs.order">
          <div class="flexRowB orderCondition">
            <div class="flexRow">
              <template v-if="!$attrs.hideorder">
                <div
                  class="cursor upStoreTime"
                  @click="
                    timeOrder = !timeOrder;
                    hotOrder = true;
                    clickOrder = true;
                    orderBy = 0;
                  "
                >
                  上架时间
                  <div
                    :class="[
                      orderBy === 0
                        ? timeOrder
                          ? 'orderUp'
                          : 'orderDown'
                        : 'orderNomal',
                    ]"
                  />
                </div>
                <div
                  class="cursor ml30 upStoreTime"
                  v-if="$attrs.objectType !== 1"
                  @click="
                    hotOrder = !hotOrder;
                    timeOrder = true;
                    clickOrder = true;
                    orderBy = 1;
                  "
                >
                  出版时间
                  <div
                    :class="[
                      orderBy === 1
                        ? hotOrder
                          ? 'orderUp'
                          : 'orderDown'
                        : 'orderNomal',
                    ]"
                  />
                </div>
                <div
                  class="cursor ml30 upStoreTime"
                  @click="
                    clickOrder = !clickOrder;
                    timeOrder = true;
                    hotOrder = true;
                    orderBy = 2;
                  "
                >
                  阅读量
                  <div
                    :class="[
                      orderBy === 2
                        ? clickOrder
                          ? 'orderUp'
                          : 'orderDown'
                        : 'orderNomal',
                    ]"
                  />
                </div>
              </template>
            </div>

            <div class="flexRow displayType">
              <div
                class="cursor displayBlock"
                :class="[
                  displayType === 'block'
                    ? 'displayBlockActive'
                    : 'displayBlock',
                ]"
                @click="changeDisplayType('block')"
                title="大图展示"
              />
              <div
                class="cursor ml17 displayList"
                :class="[
                  displayType === 'list'
                    ? 'displayListActive'
                    : 'displayList',
                ]"
                @click="changeDisplayType('list')"
                title="列表展示"
              />
            </div>
          </div>
        </template>
        <slot name="dataView" :display="displayType" />
      </div>
    </div>
  </div>
</div>
