/**
 * Created by PanJiaChen on 16/11/18.
 */

/**
 * Parse the time to string
 * @param {(Object|string|number)} time
 * @param {string} cFormat
 * @returns {string}
 */
import { store } from '@/store';

export function parseTime(time, cFormat) {
  if (arguments.length === 0) return null;

  const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}';
  let date;
  if (typeof time === 'object') {
    date = time;
  } else {
    if (typeof time === 'string' && /^[0-9]+$/.test(time))
      time = parseInt(time);

    if (typeof time === 'number' && time.toString().length === 10)
      time = time * 1000;

    date = new Date(time);
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay(),
  };
  const time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
    let value = formatObj[key];
    // Note: getDay() returns 0 on Sunday
    if (key === 'a') return ['日', '一', '二', '三', '四', '五', '六'][value];
    if (result.length > 0 && value < 10) value = `0${value}`;

    return value || 0;
  });
  return time_str;
}

/**
 * @param {number} time
 * @param {string} option
 * @returns {string}
 */
export function formatTime(time, option) {
  if (`${time}`.length === 10) time = parseInt(time) * 1000;
  else time = +time;

  const d = new Date(time);
  const now = Date.now();

  const diff = (now - d) / 1000;

  if (diff < 30) {
    return '刚刚';
  } else if (diff < 3600) {
    // less 1 hour
    return `${Math.ceil(diff / 60)}分钟前`;
  } else if (diff < 3600 * 24) {
    return `${Math.ceil(diff / 3600)}小时前`;
  } else if (diff < 3600 * 24 * 2) {
    return '1天前';
  }
  if (option) {
    return parseTime(time, option);
  } else {
    return `${
      d.getMonth() + 1
    }月${d.getDate()}日${d.getHours()}时${d.getMinutes()}分`;
  }
}

/**
 * @param {string} url
 * @returns {Object}
 */
export function param2Obj(url) {
  const search = url.split('?')[1];
  if (!search) return {};

  return JSON.parse(
    `{"${decodeURIComponent(search)
      .replace(/"/g, '\\"')
      .replace(/&/g, '","')
      .replace(/=/g, '":"')
      .replace(/\+/g, ' ')}"}`
  );
}

export function buildTree(
  list,
  parentId = 'CataloguePid',
  Id = 'CatalogueId',
  rootId = '0'
) {
  const arr = [...list];

  const rootList = arr.filter((item) => {
    if (item[parentId] === rootId) {
      item._level = 0;
      if (item.CataPrice === undefined || item.CataPrice === null) {
        item._buyInfo = { type: 'book' };
      }
      return item;
    }
  });

  function listToTreeData(bootItem, arr, parentId, Id, level) {
    bootItem.children = [];
    arr.forEach((item) => {
      if (item[parentId] === bootItem[Id]) {
        item._level = level + 1;
        /* 计算设置子节点不可读时 最近可读父节点 */
        if (item.CataPrice === undefined || item.CataPrice === null) {
          item._parentCata = bootItem._parentCata
            ? bootItem._parentCata
            : {
                CatalogueId: bootItem.CatalogueId,
                Title: bootItem.Title,
                CataPrice: bootItem.CataPrice,
                dcdOrder: bootItem.dcdOrder,
                BookId: bootItem.BookId,
                LocalFileUrl: bootItem.LocalFileUrl || '',
              };
        }
        bootItem.children.push({ ...item });
      }
    });

    if (bootItem.children.length > 0) {
      bootItem.children.forEach((item) => {
        listToTreeData(item, arr, parentId, Id, item._level);
      });
    } else {
      return false;
    }
  }

  rootList.map((rootItem) => {
    listToTreeData(rootItem, arr, parentId, Id, rootItem._level);
    return rootItem;
  });

  // console.error('rootList', rootList);

  return rootList;
}

export function getUrlParams(url) {
  const search = decodeURIComponent(url.split('?')[1]).replace(/\+/g, ' ');
  if (!search) {
    return {};
  }
  const obj = {};
  const searchArr = search.split('&');
  searchArr.forEach((v) => {
    const index = v.indexOf('=');
    if (index !== -1) {
      const name = v.substring(0, index);
      const val = v.substring(index + 1, v.length);
      obj[name] = val;
    }
  });
  return obj;
}

/**
 ** 乘法函数，用来得到精确的乘法结果
 ** 说明：javascript的乘法结果会有误差，在两个浮点数相乘的时候会比较明显。这个函数返回较为精确的乘法结果。
 ** 调用：accMul(arg1,arg2)
 ** 返回值：arg1乘以 arg2的精确结果
 **/
export function accMul(arg1, arg2) {
  var m = 0,
    s1 = arg1.toString(),
    s2 = arg2.toString();
  try {
    m += s1.split('.')[1].length;
  } catch (e) {}
  try {
    m += s2.split('.')[1].length;
  } catch (e) {}
  return (
    (Number(s1.replace('.', '')) * Number(s2.replace('.', ''))) /
    Math.pow(10, m)
  );
}

/* 小数高精度计算 */
export function accDiv(arg1, arg2) {
  var m = 0,
    m2 = 0,
    s1 = arg1.toString(),
    s2 = arg2.toString();
  try {
    m = s1.split('.')[1].length;
  } catch (e) {}
  try {
    m2 = s2.split('.')[1].length;
  } catch (e) {}
  return (
    (Number(s1.replace('.', '')) / Number(s2.replace('.', ''))) *
    Math.pow(10, m2 - m)
  );
}


/* 判断客户端类型 */
export function isPC(){  
    var userAgentInfo = navigator.userAgent;
    var Agents = new Array("Android", "iPhone", "SymbianOS", "Windows Phone", "iPad", "iPod");  
    var flag = true;  
    for (var v = 0; v < Agents.length; v++) {  
      if (userAgentInfo.indexOf(Agents[v]) > 0) { flag = false; break; }  
    }  
    return flag;  
}

export function errorImg(event){  
  event.target.src = store.state.appConfig.ERR_IMG
}
