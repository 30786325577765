/* 搜索 默认布局 包含 面包屑 左侧查询 右侧展示 filter 左侧精简查询
checkboxconfig 配置资源类型*/
<template>
  <div class="max1200 m-auto">
    <breadcrumb-pager :breadList="[$attrs.title]" />
    <template v-if="$slots.headContainer">
      <slot name="headContainer" />
    </template>
    <div class="flexRowB itemsSS layoutContainer">
      <div
        class="flexGrow0 leftQueryContainer mr10"
        v-if="$slots.leftContainer"
      >
        <slot name="leftContainer" />
      </div>
      <div class="flexGrow1">
        <div class="topCondition mb10 bgw" v-if="$attrs.filter">
          <div class="pt20 px20 pb15">
            <ul :class="{ more: isMore }">
              <!-- <li class="flexRowBS mb16" v-if="$attrs.objectType === 1">
                <span class="label-name mr35 blue">专题类别</span>
                <Checkbox
                  v-model="ListTypeVal"
                  :all="true"
                  :isLeftAlignment="true"
                  :judgingWidth="true"
                  :contentMaxWidth="996"
                  :dataList="ListType"
                >
                </Checkbox>
              </li> -->
              <!-- <li class="flexRowS">
                <span class="label-name mr16">上架时间：</span>
                <Checkbox
                  v-model="ListingVal"
                  :all="true"
                  :dataList="ListingTime"
                  @customChange="customChange($event, 1)"
                >
                  <template #custom>
                    <div class="flexRow inputContainer" @click.stop="">
                      <input
                        type="text"
                        ref="startTime"
                        v-model="startTime"
                        class="inputText"
                        @blur="updateTime(1)"
                        @keyup.enter="changeRange(1)"
                      />
                      <div class="inputLabel mx8">~</div>
                      <input
                        type="text"
                        ref="endTime"
                        v-model="endTime"
                        class="inputText"
                        @blur="updateTime(1)"
                        @keyup.enter="changeRange(1)"
                      />
                      <button
                        class="flexRow inputBtn ml8"
                        @click="changeRange(1)"
                      >
                        <img src="@/assets/images/icons/enterBtn.png" />
                      </button>
                    </div>
                  </template>
                </Checkbox>
              </li> -->
              <!-- mt16 -->
              <li class="flexRowBS">
                <span class="label-name mr35 blue">出版时间</span>
                <Checkbox
                  v-model="PublicationVal"
                  :all="true"
                  :isLeftAlignment="true"
                  :judgingWidth="true"
                  :dataList="PublicationTime"
                  @customChange="customChange($event, 2)"
                >
                  <template #custom>
                    <div class="flexRow inputContainer">
                      <input
                        type="text"
                        ref="pubstartTime"
                        v-model="pubstartTime"
                        class="inputText"
                        @blur="updateTime(2)"
                        @keyup.enter="changeRange(2)"
                      />
                      <div class="inputLabel mx8">-</div>
                      <input
                        type="text"
                        ref="pubendTime"
                        v-model="pubendTime"
                        class="inputText"
                        @blur="updateTime(2)"
                        @keyup.enter="changeRange(2)"
                      />
                      <button
                        class="flexRow inputBtn ml8"
                        @click="changeRange(2)"
                      >
                        <img src="@/assets/images/icons/enterBtn.png" />
                      </button>
                    </div>
                  </template>
                </Checkbox>
              </li>
              <li class="flexRowBS mt8" v-if="$attrs.objectType !== 1">
                <span class="label-name mr35 purple" style="margin-top: -1px"
                  >分&emsp;&emsp;类</span
                >
                <Checkbox
                  :all="true"
                  :isLeftAlignment="true"
                  :judgingWidth="true"
                  v-model="typeVal"
                  :more="typeData && typeData.length > 4"
                  :dataList="typeData"
                />
              </li>
              <li class="flexRowBS mt8">
                <span class="label-name mr35 green" style="margin-top: -1px"
                  >出版单位</span
                >
                <Checkbox
                  :all="true"
                  :isLeftAlignment="true"
                  :judgingWidth="true"
                  :contentMaxWidth="$attrs.objectType === 1 ? 996 : 702"
                  v-model="unitVal"
                  :more="true"
                  :dataList="unitList"
                />
              </li>
            </ul>
            <div
              class="flexRow moreBtn mt20 cursor"
              :class="{ more: isMore }"
              @click="bindSee"
            >
              <img src="@/assets/images/icons/arrow-t.png" class="mr4" />{{
                isMore ? "收起" : "展开"
              }}
            </div>
          </div>
        </div>

        <div class="contentCondition bgw">
          <template
            v-if="
              $attrs.countmap &&
              $attrs.checkboxconfig &&
              $attrs.checkboxconfig.list.length > 0
            "
          >
            <div class="flexRowS bookTypeListContainer pt20 px20">
              <div
                v-for="bookType in $attrs.checkboxconfig.list"
                :key="bookType.id"
                class="flexRow cursor bookTypeItem mr16"
                :class="[
                  bookType.id === checkedKey ? 'bookTypeItemActive' : '',
                ]"
                @click="
                  searchType = 'by hand';
                  checkedKey = bookType.id;
                "
              >
                {{ bookType.name }} ({{
                  ($attrs.countmap && $attrs.countmap[bookType.countProp]) || 0
                }})
              </div>
            </div>
          </template>
          <template v-if="$attrs.order">
            <div class="flexRowB orderCondition">
              <div class="flexRow">
                <template v-if="!$attrs.hideorder">
                  <div
                    class="cursor upStoreTime"
                    @click="
                      timeOrder = !timeOrder;
                      hotOrder = true;
                      clickOrder = true;
                      orderBy = 0;
                    "
                  >
                    上架时间
                    <div
                      :class="[
                        orderBy === 0
                          ? timeOrder
                            ? 'orderUp'
                            : 'orderDown'
                          : 'orderNomal',
                      ]"
                    />
                  </div>
                  <div
                    class="cursor ml30 upStoreTime"
                    v-if="$attrs.objectType !== 1"
                    @click="
                      hotOrder = !hotOrder;
                      timeOrder = true;
                      clickOrder = true;
                      orderBy = 1;
                    "
                  >
                    出版时间
                    <div
                      :class="[
                        orderBy === 1
                          ? hotOrder
                            ? 'orderUp'
                            : 'orderDown'
                          : 'orderNomal',
                      ]"
                    />
                  </div>
                  <div
                    class="cursor ml30 upStoreTime"
                    @click="
                      clickOrder = !clickOrder;
                      timeOrder = true;
                      hotOrder = true;
                      orderBy = 2;
                    "
                  >
                    阅读量
                    <div
                      :class="[
                        orderBy === 2
                          ? clickOrder
                            ? 'orderUp'
                            : 'orderDown'
                          : 'orderNomal',
                      ]"
                    />
                  </div>
                </template>
              </div>

              <div class="flexRow displayType">
                <div
                  class="cursor displayBlock"
                  :class="[
                    displayType === 'block'
                      ? 'displayBlockActive'
                      : 'displayBlock',
                  ]"
                  @click="changeDisplayType('block')"
                  title="大图展示"
                />
                <div
                  class="cursor ml17 displayList"
                  :class="[
                    displayType === 'list'
                      ? 'displayListActive'
                      : 'displayList',
                  ]"
                  @click="changeDisplayType('list')"
                  title="列表展示"
                />
              </div>
            </div>
          </template>
          <slot name="dataView" :display="displayType" />
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import BreadcrumbPager from "./BreadcrumbPager.vue";
import { defineComponent } from "@vue/composition-api";
import { yearRange } from "@/api/bookDetail";
import {
  getCateGoryByLevel,
  getAllPublish,
  getSpecialPublish,
} from "@/api/bookList";
import { Toast } from "vant";

export default defineComponent({
  components: {
    BreadcrumbPager,
  },
  setup() {},
  data() {
    let checkbox = "";
    if (this.$attrs.checkboxconfig) {
      const defaultCheckedList = (this.$attrs.checkboxconfig.list || []).filter(
        (i) => i.checked
      );
      if (defaultCheckedList.length > 0) {
        checkbox = defaultCheckedList[0].id;
      }
    }

    return {
      searchType: "",
      orderBy: 0,
      checkedKey: checkbox,
      startTime: "",
      endTime: "",
      sjTime: "",
      sjcustom: false,
      pubstartTime: "",
      pubendTime: "",
      currentDate: new Date().getFullYear(),
      cbTime: "",
      cbcustom: false,
      timeRangeStart: "",
      timeRangeEnd: "",
      value: [this.$attrs.startyear, this.$attrs.endyear],
      displayType: "block",
      timeOrder: false,
      hotOrder: true,
      clickOrder: true,
      research: false,
      typeData: [],
      typeVal: [],
      isMore: true,
      unitVal: [],
      unitList: [],
      ListingVal: [],
      ListingTime: [],
      ListTypeVal: [],
      ListType: [
        { label: "知识专题", value: 0 },
        { label: "活动专题", value: 1 },
      ],
      PublicationVal: [],
      PublicationTime: [],
    };
  },
  computed: {
    orderStatus() {
      let checkbox = "";
      if (this.$attrs.checkboxconfig && !this.$attrs.checkboxconfig.yearRange) {
        checkbox =
          this.$attrs.checkboxconfig.type === "checkbox"
            ? JSON.stringify(this.$attrs.checkboxconfig.list || [])
            : this.checkedKey;
      }
      // console.error('checkbox', checkbox)
      // console.log(this.timeOrder, "this.timeOrder");
      // console.log(this.hotOrder, "this.hotOrder");
      // console.log(this.research, "this.research");
      // console.log(checkbox, "checkbox");
      // console.log(this.value, "this.value");
      return (
        this.timeOrder +
        "_" +
        this.hotOrder +
        "_" +
        this.clickOrder +
        "_" +
        this.ListingVal +
        "_" +
        this.ListTypeVal +
        "_" +
        this.PublicationVal +
        "_" +
        this.unitVal +
        "_" +
        this.typeVal +
        "_" +
        this.sjcustom +
        "_" +
        this.cbcustom +
        "_" +
        this.research +
        "_" +
        checkbox +
        "_" +
        this.$attrs.ObjectType +
        "_" +
        JSON.stringify(this.value)
      ); //+ '_' + this.startTime + '_' + this.endTime //+ '_' + this.value[0] + "_" + this.value[1]
    },
  },
  created() {
    let times = [];
    for (let i = 0; i <= 4; i++) {
      times.push({
        label: `${this.currentDate - i}年`,
        value: `${this.currentDate - i}`,
      });
    }
    this.ListingTime = JSON.parse(JSON.stringify(times));
    this.PublicationTime = JSON.parse(JSON.stringify(times));
    // this.startTime = this.timeRangeStart;
    // this.endTime = this.timeRangeEnd;

    // this.$watch("$attrs.checkboxconfig", () => {
    //   if (this.$attrs.checkboxconfig) {
    //     const defaultCheckedList = (this.$attrs.checkboxconfig.list || []).filter(i => i.checked)
    //     console.error('defaultCheckedList', defaultCheckedList)
    //     if (defaultCheckedList.length > 0) {
    //       this.checkedKey = defaultCheckedList[0].id
    //     }
    //   }
    // }, { immediate: true })
    this.$watch("$attrs.objectType", (val) => {
      // 进入专题页，排序为出版时间时，需修改为上架时间
      if (val === 1 && this.hotOrder) {
        this.timeOrder = true;
        this.hotOrder = false;
        this.orderBy = 0;
      }
    });
    this.$watch(
      "$route.query",
      () => {
        this.searchType = "";
      },
      { immediate: true }
    );

    this.$watch(
      "$attrs.defaultkey",
      () => {
        if (this.$attrs.defaultkey) {
          this.checkedKey = this.$attrs.defaultkey;
        }
      },
      { immediate: true }
    );

    this.$watch(
      "checkedKey",
      async (newValue) => {
        if (
          this.$attrs.checkboxconfig &&
          this.$attrs.checkboxconfig.yearRange
        ) {
          const [start, end] = await this.getYearRange(newValue);
          this.timeRangeStart = start;
          this.startTime = start;
          this.value[0] = start;

          this.timeRangeEnd = end;
          this.endTime = end;
          this.value[1] = end;
          this.research = !this.research;
        }
      },
      { immediate: true }
    );

    this.$watch(
      "$attrs.ObjectType",
      async (newValue) => {
        this.getTypeData();
        this.getAllPublish();
        console.log(this.$attrs.ObjectType, "$attrs.ObjectType");
        // if (this.$attrs.ObjectType !== undefined) {
        //   const [start, end] = await this.getYearRange(newValue);
        //   this.timeRangeStart = start;
        //   this.startTime = start;
        //   this.value[0] = start;

        //   this.timeRangeEnd = end;
        //   this.endTime = end;
        //   this.value[1] = end;
        //   this.research = !this.research;
        // }
      },
      { immediate: true }
    );

    this.$watch("$attrs.startyear", (start) => {
      this.timeRangeStart = start;
      this.startTime = start;
      this.value[0] = start;
    });
    this.$watch("$attrs.endyear", (end) => {
      this.timeRangeEnd = end;
      this.endTime = end;
      this.value[1] = end;
    });

    this.$watch(
      "orderStatus",
      () => {
        this.setData();
      },
      {
        immediate: true,
      }
    );

    this.$watch(
      "searchType",
      (newValue, oldValue) => {
        this.$emit("searchtypechange", newValue);
      },
      {
        immediate: true,
      }
    );
  },
  methods: {
    async getTypeData() {
      const { code, msg, data } = await getCateGoryByLevel({
        Id: 0,
        level: 1,
        ObjectType: this.$attrs.ObjectType,
      });
      if (code === 200) {
        this.typeData = data.map(({ Title, Id }) => {
          return {
            label: Title,
            value: Id,
          };
        });
      } else {
        Toast(msg);
      }
    },
    bindSee() {
      this.isMore = !this.isMore;
    },
    async getYearRange(bookType) {
      const { code, msg, data } = await yearRange({ ObjectType: bookType });
      if (code !== 200) {
        Toast(msg);
        return ["", ""];
      }
      return [data.endYear, data.startYear];
    },
    updateTime(type) {
      console.log(type);
    },
    changeRange(type) {
      const currentDate = this.currentDate;
      if (type === 1) {
        const { startTime, endTime } = this;
        if (
          (startTime && (startTime.length !== 4 || startTime > currentDate)) ||
          (endTime && (endTime.length !== 4 || endTime > currentDate))
        ) {
          return Toast("请输入有效上架时间");
        }
        if (startTime && endTime) {
          if (startTime > endTime) {
            Toast("上架时间开始时间不能早于结束时间");
          } else {
            const sjTime = [];
            for (let i = startTime; i <= endTime; i++) {
              sjTime.push(i);
            }
            this.sjTime = sjTime.toString();
            this.sjcustom && this.setData();
          }
        } else {
          if (startTime) {
            this.$refs.endTime.focus();
          } else if (endTime) {
            this.$refs.startTime.focus();
          }
        }
      } else if (type === 2) {
        const { pubstartTime, pubendTime } = this;
        if (
          (pubstartTime &&
            (pubstartTime.length !== 4 || pubstartTime > currentDate)) ||
          (pubendTime && (pubendTime.length !== 4 || pubendTime > currentDate))
        ) {
          return Toast("请输入有效出版时间");
        }
        if (pubstartTime && pubendTime) {
          if (pubstartTime > pubendTime) {
            Toast("出版时间开始时间不能早于结束时间");
          } else {
            const cbTime = [];
            for (let i = pubstartTime; i <= pubendTime; i++) {
              cbTime.push(i);
            }
            this.cbTime = cbTime.toString();
            this.cbcustom && this.setData();
          }
        } else {
          if (pubstartTime) {
            this.$refs.pubendTime.focus();
          } else if (pubendTime) {
            this.$refs.pubstartTime.focus();
          }
        }
      }
      // this.startTime = this.value[0];
      // this.endTime = this.value[1];
      // this.value = [this.startTime, this.endTime];
      // this.research = !this.research;
    },
    customChange(status, type) {
      if (type === 1) {
        this.sjcustom = status;
      } else if (type === 2) {
        this.cbcustom = status;
      }
    },
    setData() {
      // console.error('newValue', newValue)
      let checkbox = "";
      if (this.$attrs.checkboxconfig) {
        checkbox =
          this.$attrs.checkboxconfig.type === "checkbox"
            ? (this.$attrs.checkboxconfig.list || [])
                .filter((i) => i.checked)
                .map((i) => i.id)
                .join(",")
            : this.checkedKey;
      }

      const {
        timeOrder,
        hotOrder,
        clickOrder,
        ListingVal,
        ListTypeVal,
        PublicationVal,
        typeVal,
        unitVal,
        sjcustom,
        sjTime,
        cbcustom,
        cbTime,
      } = this;
      let sortInfo = {};
      const filterInfo = {};
      const otherInfo = {};
      if (this.$attrs.objectType === 1) {
        //
        //  专题入参需要修改 待完善
        if (this.orderBy === 0) {
          // sortInfo.OnShelfDate = this.orderBy === 0 ? 'true' : 'false';
          sortInfo.orderByTime = timeOrder === true ? "0" : "1";
        } else if (this.orderBy === 2) {
          sortInfo.orderByHeat = clickOrder === true ? "0" : "1";
        }

        if (ListingVal?.length > 0) {
          filterInfo.onShelfDate = ListingVal.toString();
        } else if (sjcustom) {
          filterInfo.onShelfDate = sjTime;
        }
        if (PublicationVal?.length > 0) {
          filterInfo.publishDate = PublicationVal.toString();
        } else if (cbcustom) {
          filterInfo.publishDate = cbTime;
        }
        // if (ListTypeVal?.length > 0) {
        //   filterInfo.subjectTypes = ListTypeVal.toString();
        // }
        if (unitVal?.length > 0) {
          filterInfo.publishs = unitVal.toString();
        }
      } else {
        if (this.orderBy === 0) {
          // sortInfo.OnShelfDate = this.orderBy === 0 ? 'true' : 'false';
          sortInfo.OnShelfDateisAsc = timeOrder;
        } else if (this.orderBy === 1) {
          // sortInfo.clickThroughRate = this.orderBy === 1 ? 'true' : 'false';
          sortInfo.IssueDateAsc = hotOrder;
        } else if (this.orderBy === 2) {
          sortInfo.clickThroughRateisAsc = clickOrder;
        }
        if (ListingVal?.length > 0) {
          filterInfo.onShelfDate = ListingVal.toString();
        } else if (sjcustom) {
          filterInfo.onShelfDate = sjTime;
        }
        if (PublicationVal?.length > 0) {
          filterInfo.publishDate = PublicationVal.toString();
        } else if (cbcustom) {
          filterInfo.publishDate = cbTime;
        }
        if (typeVal?.length > 0) {
          filterInfo.cateGoryId = typeVal.toString();
        }
        if (unitVal?.length > 0) {
          filterInfo.publishs = unitVal.toString();
        }
        if (this.$attrs.ObjectType !== undefined) {
          otherInfo["ObjectType"] = this.$attrs.ObjectType;
        }
      }

      this.$emit("orderchange", {
        ...sortInfo,
        ...otherInfo,
        ...filterInfo,
        // startYear: this.value[0],
        // endYear: this.value[1],
        checkedIds: checkbox,
      });
    },
    getAllPublish() {
      if (this.$attrs.ObjectType == 1) {
        getSpecialPublish({}).then(({ data }) => {
          this.unitList = data.map((item) => {
            return {
              label: item,
              value: item,
            };
          });
        });
      } else {
        getAllPublish({
          ObjectType: this.$attrs.ObjectType,
        }).then(({ data }) => {
          this.unitList = data.map((item) => {
            return {
              label: item,
              value: item,
            };
          });
        });
      }
    },
    changeDisplayType(type: string) {
      this.displayType = type;
    },
    onChange(value) {
      // console.log('当前值：' + value);
    },
    updateCheckedKey(id) {
      this.checkedKey = id;
    },
  },
});
</script>
<style lang="less" scoped>
.layoutContainer {
  width: 1200px;
  height: 100%;
  overflow-x: hidden;
  border: 0px solid #f00;
}
.leftQueryContainer {
  width: 242px;
}
.orderCondition {
  padding: 16px 20px;
}

.label-name {
  position: relative;
  font-family: YouSheBiaoTiHei;
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  width: 60px;
  white-space: nowrap;
  &.blue {
    color: #4c84ef;
    &::after {
      position: absolute;
      left: 3px;
      bottom: 2px;
      z-index: 0;
      content: "";
      width: 57px;
      height: 8px;
      background-color: rgba(76, 132, 239, 0.2);
    }
  }
  &.purple {
    color: #8578d5;
    &::after {
      position: absolute;
      left: 3px;
      bottom: 2px;
      z-index: 0;
      content: "";
      width: 57px;
      height: 8px;
      background-color: rgba(133, 120, 213, 0.2);
    }
  }
  &.green {
    color: #72c6b4;
    &::after {
      position: absolute;
      left: 3px;
      bottom: 2px;
      z-index: 0;
      content: "";
      width: 57px;
      height: 8px;
      background-color: rgba(114, 198, 180, 0.33);
    }
  }
}
.inputText {
  width: 51px;
  height: 22px;
  border: 1px solid #f0f3f7;
  border-radius: 4px;
}
.inputBtn {
  // width: 24px;
  // height: 16px;
  // background: #ffffff;
  // box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.02);
  // border-radius: 2px 2px 2px 2px;
  img {
    width: 12px;
    height: 11px;
  }
}
.inputContainer {
  // width: 210px;
}
.more-btn {
  width: 66px;
  height: 22px;
  color: #000;
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.02);
  border-radius: 2px 2px 2px 2px;
  border: 1px solid #d9d9d9;
  img {
    width: 14px;
  }
}

.moreBtn {
  font-size: 14px;
  color: #8a8d99;
  &.more {
    img {
      transform: rotate(180deg);
    }
  }
  img {
    width: 8px;
    transition: transform 0.3s;
  }
}

.topCondition {
  ul {
    max-height: 30px;
    transition: max-height 0.3s;
    overflow: hidden;
    &.more {
      max-height: 999px;
    }
  }
}

.upStoreTime {
  display: flex;
  align-items: center;
  color: #8d9399;
  div {
    margin-left: 4px;
  }
}

.bookTypeItem {
  height: 32px;
  line-height: 32px;
  border-radius: 20px;
  background-color: #f3f4f5;
  font-family: Noto Sans SC;
  font-size: 14px;
  font-weight: 400;
  color: #8d9399;
  padding: 0 16px;
  &.bookTypeItemActive {
    background: #0075e2;
    border-color: #0075e2;
    color: #fff;
  }
}
</style>
