/**
 *格式化时间
 *yyyy-MM-dd hh:mm:ss
 */
 import { bookTypeNameMap } from "@/config/baseConfig.js"

export function formatDate(time, fmt) {
  if (time === undefined || '') {
    return;
  }
  const date = new Date(time);
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(
      RegExp.$1,
      (date.getFullYear() + '').substr(4 - RegExp.$1.length)
    );
  }
  const o = {
    'M+': date.getMonth() + 1,
    'd+': date.getDate(),
    'h+': date.getHours(),
    'm+': date.getMinutes(),
    's+': date.getSeconds(),
  };
  for (const k in o) {
    if (new RegExp(`(${k})`).test(fmt)) {
      const str = o[k] + '';
      fmt = fmt.replace(
        RegExp.$1,
        RegExp.$1.length === 1 ? str : padLeftZero(str)
      );
    }
  }
  return fmt;
}

export function dateStr(value) {
  if (value && !value.trim()) return ''
  return formatDate(value, 'yyyy-MM-dd');
}
export function monthStr(value) {
  return formatDate(value, 'yyyy-MM');
}

export function monthCn(value, isShowDay) {
  return formatDate(value, isShowDay ? 'yyyy年MM月dd日' : 'yyyy年MM月');
}

function padLeftZero(str) {
  return ('00' + str).substr(str.length);
}
/*
 * 隐藏用户手机号中间四位
 */
export function hidePhone(phone) {
  return phone.replace(/(\d{3})\d{4}(\d{4})/, '$1****$2');
}

export function simpleText(val) {
  if (val != null && val != '') {
    let reg = /[\u4e00-\u9fa5]/g;
    let names = val.match(reg);
    val = names ? names.join('') : '';
    return val;
  } else {
    return '';
  }
}


export function newSimpleText(val) {
  if(!val) return ''
  
  if (val !== null && val !== '') {
    return val.replace(/<[^>]*>/g, '');
  } else {
    return '';
  }
}

export function bookTypeName(val) {
  return bookTypeNameMap[val]
}
