import Vue from 'vue'
import type { RouteConfig } from 'vue-router'
import VueRouter from 'vue-router'
import NotFound from '@/views/NotFound.vue'
import { BookType } from '@/config/baseConfig'
import {isPC} from '@/utils/index'
import { getShareUrl } from "@/utils/wxShare";

const { WEB_BASE_PUBLICK_PATH, WEB_BASE_URL } = window.__appBaseConfig__

const originalPush = VueRouter.prototype.push
const originReplace = VueRouter.prototype.replace

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err)
}

VueRouter.prototype.replace = function (location, onComplete, onAbort) {
  if (onComplete===undefined && onAbort===undefined) {
    return originReplace.call(this, location, onComplete, onAbort).catch(() => {})
  } else {
    originReplace.call(this, location, onComplete, onAbort)
  }
}

Vue.use(VueRouter)

export const routes: RouteConfig[] = [
  {
    path: '/',
    name: 'Index',
    component: () => import('@/layouts/DefaultLayout.vue'),
    redirect: 'index',
    children: [
      {
        path: '/login',
        name: 'login',
        component: () => import('@/views/login/index.vue'),
      },
      {
        path: '/index',
        name: '首页',
        meta: {
          activeKey: '/index',
        },
        component: () => import('@/views/index/index.vue'),
      },
      {
        path: '/detail',
        name: '详情',
        component: () => import('@/views/detail/index.vue'),
        meta: {
          activeKey: '/list/ebook' + BookType.ebook,
          path: [
            {
              name: '电子书',
              path: '/list/ebook?id=53',
            },
          ],
        },
      },
      {
        path: '/szjcDetail',
        name: '详情',
        component: () => import('@/views/detail/index.vue'),
        meta: {
          activeKey: '/list/ebook' + BookType.dbook,
          path: [
            {
              name: '数字教材',
              path: '/list/ebook?id=101',
            },
          ],
        },
      },
      {
        path: '/soundBook',
        name: '有声书详情',
        component: () => import('@/views/detail/soundBook.vue'),
        meta: {
          activeKey: '/list/ebook' + BookType.sbook,
          path: [
            {
              name: '有声书',
              path: '/list/ebook?id=54',
            },
          ],
        },
      },
      {
        path: '/localAudio',
        name: '关联资源音频',
        component: () => import('@/views/reader/localAudio.vue'),
        meta: {
          activeKey: '/list/ebook' + BookType.course,
          path: [
            {
              name: '有声书',
              path: '/list/ebook?id=54',
            },
          ],
        },
      },
      {
        path: '/courseBook',
        name: '视频书详情',
        component: () => import('@/views/detail/courseBook.vue'),
        meta: {
          activeKey: '/list/ebook' + BookType.course,
          path: [
            {
              name: '课程',
              path: '/list/ebook?id=55',
            },
          ],
        },
      },
      {
        path: '/localVideo',
        name: '关联资源',
        component: () => import('@/views/reader/localVideo.vue'),
        meta: {
          hideSideBar: true,
          activeKey: '/list/ebook' + BookType.course,
          appendMore: true,
          path: [
            {
              name: '课程',
              path: '/list/ebook?id=55',
            },
          ],
        },
      },

      {
        path: '/textPaper',
        name: '文字稿',
        component: () => import('@/views/detail/textPaper.vue'),
        meta: {
          path: [
            {
              name: '首页',
              path: '/index',
            },
            {
              name: '课程',
              path: '/list/ebook?id=55',
            },
          ],
        },
      },
      {
        path: '/comment',
        name: '我的评论列表',
        component: () => import('@/views/my/comment/index.vue'),
      },
      {
        path: '/list/ebook',
        name: '电子书列表',
        component: () => import('@/views/bookList/index.vue'),
      },
      {
        path: '/list/search',
        name: '电子书搜索',
        component: () => import('@/views/bookList/search.vue'),
      },
      {
        path: '/list/hot',
        name: '榜单',
        component: () => import('@/views/bookList/HotList.vue'),
      },
      {
        path: '/list/university',
        name: '合作院校',
        component: () => import('@/views/university/index.vue'),
      },
      {
        path: '/list/universityDetail',
        name: '合作院校详情',
        component: () => import('@/views/university/detail.vue'),
      },
      {
        path: '/list/category',
        name: '电子书分类搜索',
        meta: {
          activeKey: '/list/category',
        },
        component: () => import('@/views/bookList/category.vue'),
      },
      {
        path: '/list/special',
        name: '专题列表',
        meta: {
          title: '专题',
          activeKey: '/list/special',
        },
        component: () => import('@/views/bookList/special.vue'),
      },
      {
        path: '/special/detail',
        name: '专题详情',
        meta: {
          activeKey: '/list/special',
        },
        component: () => import('@/views/special/specialDetail.vue'),
      },
      {
        path: '/special/subSpecial',
        name: '专题资源详情',
        meta: {
          activeKey: '/list/special',
        },
        component: () => import('@/views/special/subSpecial.vue'),
      },
      {
        path: '/my',
        name: '我的',
        component: () => import('@/layouts/myLayout.vue'),
        redirect: '/my/bookshelf',
        children: [
          {
            path: '/my/index',
            name: '我的首页',
            component: () => import('@/views/my/index.vue'),
          },
          {
            path: '/my/bookshelf',
            name: '我的书架',
            component: () => import('@/views/my/bookshelf/index.vue'),
          },
          {
            path: '/my/coupon',
            name: '我的优惠券',
            component: () => import('@/views/my/coupon/index.vue'),
          },
          {
            path: '/my/exchange',
            name: '我的兑换',
            component: () => import('@/views/my/exchange/index.vue'),
          },

          {
            path: '/my/buyList',
            name: '我的已购',
            component: () => import('@/views/my/buyList/index.vue'),
          },
          {
            path: '/my/selfTest',
            name: '我的自测',
            component: () => import('@/views/my/selfTest/index.vue'),
          },

          {
            path: '/my/certificate',
            name: '我的证书',
            component: () => import('@/views/my/certificate/index.vue'),
          },

          {
            path: '/my/certificateDetail',
            name: '证书详情',
            component: () => import('@/views/my/certificate/detail.vue'),
          },

          {
            path: '/my/comment',
            name: '我的评论',
            component: () => import('@/views/my/comment/index.vue'),
          },

          {
            path: '/my/latestRead',
            name: '最近阅读',
            component: () => import('@/views/my/latestRead/index.vue'),
          },
          {
            path: '/my/message',
            name: '消息中心',
            component: () => import('@/views/my/message/index.vue'),
          },

          {
            path: '/my/about',
            name: '联系我们',
            component: () => import('@/views/my/about.vue'),
          },

          {
            path: '/my/messageDetail',
            name: '消息详情',
            component: () => import('@/views/my/message/detail.vue'),
          },

          {
            path: '/my/settings',
            name: '系统设置',
            component: () => import('@/views/my/settings/index.vue'),
          },
          {
            path: '/my/settingsDetail',
            name: '设置详情',
            component: () => import('@/views/my/settings/detail.vue'),
          },
          {
            path: '/my/order',
            name: '我的订单',
            component: () => import('@/views/my/order/index.vue'),
          },
          {
            path: '/my/buyCar',
            name: '购物车',
            component: () => import('@/views/my/buyCar/index.vue'),
          },
          {
            path: '/my/ticket',
            name: '我的发票',
            component: () => import('@/views/my/ticket/index.vue'),
          },
        ],
      },
    ],
  },
  {
    path: '/reader',
    name: '阅读器',
    meta: {
      hideSideBar: true,
    },
    component: () => import('@/views/reader/index.vue'),
  },
  {
    path: '/szjcReader',
    name: '数字教材阅读器',
    meta: {
      hideSideBar: true,
    },
    component: () => import('@/views/reader/szjcReader.vue'),
  },
  {
    path: '/videoPlayer',
    name: '视频阅读器',
    component: () => import('@/views/reader/videoPlayer.vue'),
  },
  {
    path: '/audioPlayer',
    name: '音频阅读器',
    component: () => import('@/views/reader/audioPlayer.vue'),
  },
  {
    path: '/payResult',
    name: '支付接口回调页面',
    component: () => import('@/views/payResult/index.vue'),
  },
  {
    path: '/scanutil',
    name: '短链接',
    component: () => import('@/views/scanutil/index.vue'),
  },
  {
    path: '/:path(.*)',
    name: 'NotFound',
    component: NotFound,
  },
]

const router = new VueRouter({
  base: WEB_BASE_PUBLICK_PATH,
  mode: 'hash',
  routes,
})

const goH5Details = {
  '/szjcDetail': '101',
  '/detail': '53',
  '/soundBook': '54',
  '/courseBook': '55',
  '/special/subSpecial': '1',
  '/special/detail': '1'
}

router.beforeEach((to, from, next) => {
  document.body.scrollTop = 0
  // firefox
  document.documentElement.scrollTop = 0

  if(!isPC()){
    const currentPath = to.path
    const id = to.query.id
    if (goH5Details[currentPath]) {
      window.location.href= getShareUrl(goH5Details[currentPath], id)
    } else {
      const baseUrl = `${WEB_BASE_URL}2024h5/#/home`;
      window.location.href=baseUrl
    }
    return
  }
  
  next()
})


// 哀悼日--首页置灰
// router.afterEach((to, from) => {
//   if (location.href.indexOf('#/index') !== -1) {
//     $('body').addClass('indexPage')
//   } else {
//     $('body').removeClass('indexPage')
//   }
// })

export default router
