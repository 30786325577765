import { useStorage } from '@vueuse/core';
const localSearchInfo = useStorage('serachInfo', []);
const state = {
  userName: '',
  defaultSearch:'',
  searchList: localSearchInfo.value,
  hotList: [],
  historyIndex: '',
  hotIndex: '',
  selectLevel1List: [], // 一级分类列表
  selectLevel1Info: {}, // 选中的一级分类的信息
};
const mutations = {
  SET_USER_NAME(state, name) {
    state.userName = name;
  },
  ADD_SEARCH(state, payload) {
    const searchId = +new Date();
    if (localSearchInfo.value.length >= 10) {
      localSearchInfo.value.splice(0, 1);
    }
    localSearchInfo.value.push({ id: searchId, text: payload });
    state.historyIndex = searchId;
  },
  DELETE_SEARCH(state) {
    localSearchInfo.value.splice(0, localSearchInfo.value.length);
  },
  UPDATE_HIT_INDEX(state, payload) {
    state[payload.prop] = payload.id;
  },
  Default_Search(state,text){
    state.defaultSearch = text;
  }
};
const actions = {
  // 添加搜索记录
  addSearch({ commit }, text) {
    if (text) {
      commit('ADD_SEARCH', text);
    }
  },
  deleteSearch({ commit }) {
    commit('DELETE_SEARCH');
  },
  updateHitIndex({ commit }, payload) {
    commit('UPDATE_HIT_INDEX', payload);
  },
};
export default {
  state,
  mutations,
  actions,
};
