<template>
  <div class="flexRow cursor upStoreTime" @click="change">
    <slot></slot>
    <div class="ml4" :class="getClass()" />
  </div>
</template>

<script>
export default {
  created() {
    if (this.$attrs.value === "") {
      this.active = 0
    } else if (this.$attrs.value === true) {
      this.active = 2
    } else if (this.$attrs.value === false) {
      this.active = 1
    }
  },
  data() {
    return {
      active: 0,
    }
  },
  methods: {
    reset() {
      this.active = 0
      this.$emit("input", "")
    },
    change() {
      this.active += 1
      if (this.active > 2) {
        this.active = 1
      }
      if (this.active == 0) {
        this.$emit("input", "")
      } else if (this.active == 1) {
        this.$emit("input", false)
      } else if (this.active == 2) {
        this.$emit("input", true)
      }
      this.$emit("reload")
    },
    getClass() {
      if (this.active == 0) {
        return "orderNomal"
      } else if (this.active == 1) {
        return "orderDown"
      } else if (this.active == 2) {
        return "orderUp"
      }
    },
  },
}
</script>

<style lang="less" scoped></style>
