<!-- 分页组件 -->
<template>
  <div class="flexRow paginationContainer" v-if="list.length > 0">
    <div class="main">
      <div class="mr20">
        共 {{ li }} 页/
        <span style="color:#028BFF;">{{ total }}</span> 条数据
      </div>
      <!-- <button class="first" @click="First" v-bind:disabled="a">{{ first }}</button>
      <button class="prev" @click="Prev" :disabled="b">{{ prev }}</button>-->
      <ul>
        <li style="color: #028BFF;" v-if="beforeMore.length">...</li>
        <li
          v-for="item in list"
          @click="buttonPage(item)"
          :class="{ active: currentPage == item }"
        >{{ item }}</li>
        <li class="flexRow" style="color: #028BFF;" v-if="afterMore.length">...</li>
      </ul>
      <!-- <button class="next" @click="Next" :disabled="c">{{ next }}</button>
      <button class="last" @click="Last" :disabled="d">{{ last }}</button>-->
      <span class="ml20">跳至</span>
      <input v-model="value" @blur="change" />
      <span class="ml10">页</span>
      <!-- <button @click="just">跳转</button> -->
    </div>
  </div>
</template>
<script lang="ts">
import Vue from 'vue'
import { Toast } from 'vant'

Vue.use(Toast)

export default {
  name: "BaseListPager",
  data() {
    return {
      first: '首页',
      last: "尾页",
      prev: "上一页",
      next: "下一页",
      a: false,
      b: false,
      c: false,
      d: false,
      page: 1,
      pageSize: 10,
      total: 0,//总数
      list: [], //页数
      currentPage: 1,//当前页
      li: 1,
      value: 1,
      input: 0,
      beforeMore: [],
      afterMore: []
    }
  },
  props: ["config"],
  created() {
    this.$watch("config", (newValue => {
      this.list = []
      this.beforeMore = []
      this.afterMore = []
      this.total = newValue.total
      this.pageSize = newValue.pageSize
      this.currentPage = newValue.pageNum + 1
      this.li = newValue.pages;
      if (this.li == 1) {
        this.a = true
        this.b = true
        this.c = true
        this.d = true
      }
      const showNum = this.currentPage < 5 ? 9 : 6
      for (var i = 1; i <= this.li; i++) {
        if (Math.abs(this.currentPage - i) < showNum) {
          this.list.push(i)
        } else {
          if (i < this.currentPage) {
            this.beforeMore.push(i)
          } else if (i > this.currentPage) {
            this.afterMore.push(i)
          }
        }
      }
      // console.log(this.currentPage)
      this.value = this.currentPage
      this.input = this.currentPage
      if (this.currentPage == 1) {
        this.a = true
        this.b = true
        this.c = false
        this.d = false
      } else if (this.currentPage == this.li) {
        this.a = false
        this.b = false
        this.c = true
        this.d = true
      } else {
        this.a = false
        this.b = false
        this.c = false
        this.d = false
      }
    }), { deep: true, immediate: true })
  },
  methods: {
    change(val) {
      const inputPageNum = parseInt(val.target.value)
      let showNum = inputPageNum;
      if (inputPageNum <= 0) {
        showNum = 1
      }
      if (inputPageNum > this.li) {
        showNum = this.li
      }
      this.value = showNum || 1
      this.just()
    },
    just() {
      this.buttonPage(this.value);
    },
    First() {
      // console.log("First")
      this.buttonPage(1);
    },
    Last() {
      // console.log("Last")
      this.buttonPage(this.li);
    },
    Prev() {
      // console.log("Prev")
      if (this.currentPage > 1) {
        this.buttonPage(this.currentPage - 1);
      }

    },
    Next() {
      // console.log("Next")
      if (this.currentPage < this.li) {
        this.buttonPage(this.currentPage + 1);
      }

    },
    buttonPage(val) {
      // console.log(val)
      if (val == 1) {
        this.a = true
        this.b = true
        this.c = false
        this.d = false
      } else if (val == this.li) {
        this.a = false
        this.b = false
        this.c = true
        this.d = true
      } else {
        this.a = false
        this.b = false
        this.c = false
        this.d = false
      }
      this.value = val
      this.currentPage = val
      this.$emit("currentPage", val)
    }
  }
}
</script>
<style lang="less" scoped>
.paginationContainer {
  padding: 0px;
  background: #fff;
  font-size: 12px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #b4b4b4;
  .main {
    height: 50px;
    display: flex;
    align-items: center;
  }
  .main input {
    outline: none;
    padding: 0;
    margin: 0;
    border: 0;
    width: 40px;
    text-align: center;
    padding: 0px 10px;
    margin-left: 10px;
    border: 1px solid #eeeeee;
  }
  .main button {
    padding: 5px 10px;
    margin: 0 5px;
    background-color: #ffffff;
  }
  ul {
    margin: 0;
    padding: 0;
    display: flex;
  }
  li {
    list-style: none;
    padding: 0px 10px;
    margin: 0;
    font-size: 12px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #b4b4b4;
    margin-right: 15px;
    cursor: pointer;
  }
  .active {
    background-color: #f5f5f5;
    border: 0px dashed #028BFF;
    color: #028BFF;
    cursor: not-allowed;
  }
}
</style>