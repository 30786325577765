
<div class="flexRow paginationContainer" v-if="list.length > 0">
  <div class="main">
    <div class="mr20">
      共 {{ li }} 页/
      <span style="color:#028BFF;">{{ total }}</span> 条数据
    </div>
    <!-- <button class="first" @click="First" v-bind:disabled="a">{{ first }}</button>
    <button class="prev" @click="Prev" :disabled="b">{{ prev }}</button>-->
    <ul>
      <li style="color: #028BFF;" v-if="beforeMore.length">...</li>
      <li
        v-for="item in list"
        @click="buttonPage(item)"
        :class="{ active: currentPage == item }"
      >{{ item }}</li>
      <li class="flexRow" style="color: #028BFF;" v-if="afterMore.length">...</li>
    </ul>
    <!-- <button class="next" @click="Next" :disabled="c">{{ next }}</button>
    <button class="last" @click="Last" :disabled="d">{{ last }}</button>-->
    <span class="ml20">跳至</span>
    <input v-model="value" @blur="change" />
    <span class="ml10">页</span>
    <!-- <button @click="just">跳转</button> -->
  </div>
</div>
