<template>
  <div id="app" class="m-auto fullWidth">
    <transition name="fade">
      <RouterView />
    </transition>
  </div>
</template>
<script lang="ts">
import Vue from "vue"
import { mapState } from "vuex"
import { defineComponent } from "@vue/composition-api"
import { Button, Toast } from "vant"
import { getBookCountByType } from "@/api/bookDetail"
import { getUserInfo } from "@/api/user"
import { getAppConfig } from "@/api/index"
import { getPlatformImgApi, getWebSiteConfigApi, getAllPlatformListApi } from "@/api/home.js"

Vue.use(Toast)
Vue.use(Button)

import Qs from "qs"

import SideBar from "@/components/SideBar.vue"

export default defineComponent({
  computed: {
    ...mapState(["userInfo", "pathInfo"]),
  },
  components: {
    SideBar,
  },
  async created() {
    await this.getAppConfig()
    this.$watch(
      "$route",
      () => {
        let query = Qs.parse(location.search.substring(1))
        if (!location.search) {
          let queryString = ""
          queryString = location.hash.replace("#/index?", "")

          query = Qs.parse(queryString) /* 登录回调地址 */
        }
        let id_token = query.token
        if (id_token) {
          this.$store.dispatch("LOGIN", { token: id_token }).then(
            s => {
              if (this.pathInfo.url) {
                window.location.href =
                  this.pathInfo.url || `${location.origin}${location.pathname}#/index`
                this.$store.dispatch("savePathInfo", { url: "" })
              }
            },
            e => {
              // window.location.href = `${location.origin}${location.pathname}#/home`
            }
          )
        }
      },
      { immediate: true }
    )

    const { code, data, msg } = await getBookCountByType({ ObjectType: 54 })
    if (code !== 200) {
      this.$toast(msg)
    }
    this.$store.commit("CHANGE_BOOK_NUM", data !== 0)
  },
  methods: {
    async getAppConfig() {
      const { code, msg, data: configMap = {} } = await getAppConfig()
      if (code !== 200) {
        this.$toast(msg)
        return
      }

      let errImg = (await getPlatformImgApi()).data
      window.__appBaseConfig__.ERR_IMG = errImg

      // https://book.ingpaper.com/downloadFile/2022/05/16/0506ed1e-5464-4f7c-aa22-e141279aa427.jpg

      let logo = (await getAllPlatformListApi()).data

      if (logo) {
        console.log(logo, "gogogogo")

        window.__appBaseConfig__.WEB_APP_LOGO_PATH = logo.find(item => item.type == "logo")?.value
        window.__appBaseConfig__.WEB_APP_SHARE_LOGO_PATH = logo.find(
          item => item.type == "poster"
        )?.value
        $("link[rel=icon]")[0].href = logo.find(item => item.type == "icon")?.value
        window.__appBaseConfig__.title = logo.find(item => item.type == "icon_text")?.value
        document.title = window.__appBaseConfig__.title || "数字教材出版平台"
      }

      let systemInfo = (await getWebSiteConfigApi()).data
      console.log(systemInfo, "---------systemInfosystemInfosystemInfo---------")

      if (systemInfo["business"]) {
        window.__appBaseConfig__.systemInfo.SERVICE_COPER = systemInfo["business"]
      }
      if (systemInfo["email"]) {
        window.__appBaseConfig__.systemInfo.SERVICE_EMAIL = systemInfo["email"]
      }
      if (systemInfo["tel"]) {
        window.__appBaseConfig__.systemInfo.SERVICE_TEL = systemInfo["tel"]
      }
      if (systemInfo["version"]) {
        window.__appBaseConfig__.systemInfo.SYSTEM_VERSION = systemInfo["version"]
      }
      console.log(window.__appBaseConfig__, "------------------")

      this.$store.commit("UPDATE_APP_CONFIG", window.__appBaseConfig__)
    },
  },
})
</script>

<style lang="less">
.fullWidth {
  max-width: 1920px;
}
#app {
  overflow: auto;
}
@font-face {
  font-family: "YouSheBiaoTiHei";
  src: url("@/assets/font/YouSheBiaoTiHei.ttf");
  font-weight: normal;
  font-style: normal;
}
</style>
