
<div class="flexRowBS checkbox-box" style="flex: auto">
  <ul
    class="flexRowS flexWrap checkBoxWarp"
    ref="checkBoxWarp"
    :class="{ line1: $attrs.more && !more }"
    :style="{ paddingLeft: $attrs.isLeftAlignment ? '80px' : '0px' }"
  >
    <li
      v-if="$attrs.all"
      class="flexRowS mr52 cursor"
      :class="[$attrs.isLeftAlignment ? 'allCheckB' : '']"
      @click="bindAll"
    >
      <!-- <div :class="[checkedAll ? 'checkboxCheckedIcon' : 'checkboxIcon']" /> -->
      <div :class="[checkedAll ? 'active' : '']">全部</div>
    </li>
    <template v-if="$attrs.dataList && $attrs.dataList.length">
      <li
        v-for="(item, index) in $attrs.dataList"
        :key="index"
        class="flexRowS mr52 checkItem"
        @click="bindItem(item)"
      >
        <!-- <div :class="[item.check ? 'checkboxCheckedIcon' : 'checkboxIcon']" /> -->
        <div :class="[item.check ? 'active' : '', 'cursor']">
          {{ item.label }}
        </div>
      </li>
    </template>
    <li v-if="$slots.default" class="flexRowS cursor">
      <slot></slot>
    </li>
  </ul>
  <div
    v-if="$slots.custom"
    class="flexRowS cursor customBox pt4"
    @click="bindCustom"
  >
    <div class="flexRow customAuto">
      <!-- <div :class="[custom ? 'checkboxCheckedIcon' : 'checkboxIcon']" /> -->
      <div class="mr8" :class="[custom ? 'active' : '']">自定义</div>
    </div>
    <slot name="custom"></slot>
  </div>
  <button
    class="flexRow more-btn"
    v-if="$attrs.more && isLine"
    @click="more = !more"
  >
    <img
      src="@/assets/images/icons/more-r.png"
      :class="{ activeBtn: $attrs.more && !more }"
    />
    <!-- {{ more ? "收起" : "更多"}} -->
  </button>
</div>
