
<div class="breadcrumb-pager flexRowS my10">
  <img src="@/assets/images/icons/home.png" />
  <span class="cursor" @click="$router.replace('/')">首页</span>
  <span v-for="(text, index) in breadList" :key="index">
    <i>></i>
    <b
      v-if="text && text.name"
      class="cursor"
      @click="$router.replace(text.path)"
    >
      {{ text.name }}
    </b>
    <template v-else>
      {{ text }}
    </template>
  </span>
</div>
