import request from '@/utils/request';
export const api = {
  getPlatformImg: '/dev-api/webApi/platformConfig/getPlatformImg',
  getWebSiteConfig: '/dev-api/webApi/platformConfig/getWebSiteConfig',
  getAllPlatformList: '/dev-api/webApi/platformConfig/getAllPlatformList',
  getFooter: '/dev-api/business/websiteFiling/getWebSiteFiling',
};

// 优惠券列表
export function getPlatformImgApi (params) {
  return request({
    url: api.getPlatformImg,
    method: 'get',
    params,
    hideloading: true,
  });
}

// 侧边栏
export function getWebSiteConfigApi (params) {
  return request({
    url: api.getWebSiteConfig,
    method: 'get',
    params,
    hideloading: true,
  });
}

// 导航图
export function getAllPlatformListApi (params) {
  return request({
    url: api.getAllPlatformList,
    method: 'get',
    params,
    hideloading: true,
  });
}

export function getFooterApi (params) {
  return request({
    url: api.getFooter,
    method: 'get',
    params,
    hideloading: true,
  });
}
