<template>
  <div class="flexRowBS checkbox-box" style="flex: auto">
    <ul
      class="flexRowS flexWrap checkBoxWarp"
      ref="checkBoxWarp"
      :class="{ line1: $attrs.more && !more }"
      :style="{ paddingLeft: $attrs.isLeftAlignment ? '80px' : '0px' }"
    >
      <li
        v-if="$attrs.all"
        class="flexRowS mr52 cursor"
        :class="[$attrs.isLeftAlignment ? 'allCheckB' : '']"
        @click="bindAll"
      >
        <!-- <div :class="[checkedAll ? 'checkboxCheckedIcon' : 'checkboxIcon']" /> -->
        <div :class="[checkedAll ? 'active' : '']">全部</div>
      </li>
      <template v-if="$attrs.dataList && $attrs.dataList.length">
        <li
          v-for="(item, index) in $attrs.dataList"
          :key="index"
          class="flexRowS mr52 checkItem"
          @click="bindItem(item)"
        >
          <!-- <div :class="[item.check ? 'checkboxCheckedIcon' : 'checkboxIcon']" /> -->
          <div :class="[item.check ? 'active' : '', 'cursor']">
            {{ item.label }}
          </div>
        </li>
      </template>
      <li v-if="$slots.default" class="flexRowS cursor">
        <slot></slot>
      </li>
    </ul>
    <div
      v-if="$slots.custom"
      class="flexRowS cursor customBox pt4"
      @click="bindCustom"
    >
      <div class="flexRow customAuto">
        <!-- <div :class="[custom ? 'checkboxCheckedIcon' : 'checkboxIcon']" /> -->
        <div class="mr8" :class="[custom ? 'active' : '']">自定义</div>
      </div>
      <slot name="custom"></slot>
    </div>
    <button
      class="flexRow more-btn"
      v-if="$attrs.more && isLine"
      @click="more = !more"
    >
      <img
        src="@/assets/images/icons/more-r.png"
        :class="{ activeBtn: $attrs.more && !more }"
      />
      <!-- {{ more ? "收起" : "更多"}} -->
    </button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      checkedAll: true,
      custom: false,
      more: false,
      isLine: true,
    };
  },
  created() {
    if (this.$attrs.dataList?.length > 0 && this.$attrs.value?.length > 0) {
      this.$attrs.value.forEach((item) => {
        let index = this.$attrs.dataList.findIndex((sub) => sub.value == item);
        if (index > -1) {
          this.$attrs.dataList[index].check = true;
        }
      });
    }
  },
  watch: {
    "$attrs.dataList": {
      handler() {
        this.$attrs.judgingWidth &&
          this.$nextTick(() => {
            const contentWidth = this.$refs.checkBoxWarp.clientWidth;
            this.isLine = !(
              contentWidth <= (this.$attrs.contentMaxWidth || 702)
            );
          });
      },
      immediate: true,
    },
  },
  methods: {
    bindAll() {
      this.custom = false;
      if (!this.checkedAll) {
        this.checkedAll = !this.checkedAll;
        this.$attrs.dataList.forEach((item) => {
          this.$set(item, "check", false);
        });
        this.$emit("customChange", this.custom);
      }
      this.$nextTick(() => {
        this.change();
      });
    },
    bindItem(item) {
      if (this.$attrs.isRadio) {
        for (const item of this.$attrs.dataList) {
          item.check = false;
        }
      }
      this.custom = false;
      this.$set(item, "check", !item.check);
      this.$nextTick(() => {
        if (this.$attrs.dataList.some((item) => item.check)) {
          this.checkedAll = false;
        } else {
          this.checkedAll = true;
        }
        this.change();
      });
    },
    bindCustom() {
      if (this.$attrs.isRadio && this.custom) {
        return;
      }
      this.custom = true;
      if (this.custom) {
        this.checkedAll = false;
        this.$attrs.dataList.forEach((item) => {
          this.$set(item, "check", false);
        });
        this.$emit("input", []);
        this.$emit("change", []);
      }
      this.$emit("customChange", this.custom);
    },
    change() {
      let data = this.$attrs.dataList.filter((item) => item.check);
      let vals = data;
      if (!this.$attrs.showLabel) {
        vals = data.map((item) => item.value);
      }
      console.log(vals);
      this.$emit("input", vals);
      this.$emit("change", vals);
    },
  },
};
</script>

<style lang="less" scoped>
.checkBoxWarp {
  font-family: Noto Sans SC;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: #222;
  > li {
    padding: 4px 0;
  }
  .active {
    color: #0075e2;
    font-weight: bold;
  }
  .allCheckB {
    margin-left: -80px;
  }
}
.checkboxCheckedIcon,
.checkboxIcon {
  width: 16px;
  height: 16px;
}

.more-btn {
  min-width: 18px;
  height: 26px;
  padding-top: 4px;
  // color: #8d9399;
  img {
    transition: transform 0.3s;
    width: 18px;
  }
  .activeBtn {
    transform: rotate(90deg);
  }
}

.line1 {
  height: 22px;
  overflow: hidden;
}

.customBox {
  .active {
    color: #0075e2;
    font-weight: bold;
  }
}
</style>
