
<div
  class="cursor bookContainer"
  v-if="$attrs.book"
  :key="$attrs.book.Id || $attrs.book.id"
  @click="goDetail($attrs.book)"
  :class="[
    $attrs.flexRow || $attrs.flexrow ? 'flexRowSB rowLayout' : 'normalLayout',
    $attrs.flexrow ? 'flexRowSB' : '',
    $attrs.shadow ? 'shadow' : '',
    $attrs.noPadding ? 'noPadding' : '',
    $attrs.largePadding ? 'largePadding' : '',
    $attrs.flexClass,
  ]"
  :style="$attrs.style || {}"
>
  <div
    class="flexGrow0 leftBookFace"
    :key="$attrs.book.Id || $attrs.book.id"
    :style="{
      width: $attrs.width,
      height: $attrs.height,
      background: `url(${
        ($attrs.book && $attrs.book.resourceImg) ||
        $attrs.book.CoverUrl ||
        $attrs.book.img ||
        $attrs.url ||
        $attrs.book.subImg ||
        $store.state.appConfig.ERR_IMG
      }) center center no-repeat`,
      backgroundSize: $attrs.bgsize || 'cover',
    }"
  >
    <div v-if="$attrs.book.exchangeType == 1" class="duiIcon"></div>
    <div v-if="$attrs.book.exchangeType == 2" class="quanIcon"></div>
    <div v-if="$attrs.showVideoIcon" class="videoIcon"></div>
    <div
      class="deleteIcon"
      v-if="$attrs.showdelete"
      @click.stop="deleteBook($attrs.book.id)"
    ></div>
    <div class="flexRow bookTypeName">
      {{
        ($attrs.book && bookTypeNameMap[$attrs.book.ObjectType]) ||
        ($attrs.book && bookTypeNameMap[$attrs.book.objectType]) ||
        ($attrs.book && bookTypeNameMap[$attrs.book.resourceType]) ||
        "专题"
      }}
    </div>
  </div>
  <div
    class="flexGrow1 rightBootDetail"
    :class="[$attrs.rightFlex ? 'rightFlexRow' : '']"
  >
    <div
      class="topFixHeightContainer"
      :class="[
        $attrs.namePadding ? 'px18' : '',
        $attrs.resetNamePadding,
        $attrs.vertical ? 'contentVertical' : '',
      ]"
    >
      <slot name="bookName"></slot>
      <div
        v-if="!$attrs.hidename"
        :style="$attrs.bookNameClass"
        class="bookName"
        v-text="
          $attrs.book
            ? $attrs.book.catalogueName ||
              $attrs.book.resourceName ||
              $attrs.book.bookName ||
              $attrs.book.Title ||
              $attrs.book.title ||
              $attrs.book.subTitle
            : ''
        "
      ></div>
      <!--    章节名称    -->
      <slot name="chapterName"></slot>
      <div style="margin-top: 4px;">
        <!-- 作者 -->
        <div
          class="bookAuthor textflow1"
          :class="[$attrs.authCenter ? 'flexRow' : '']"
          v-if="!$attrs.hideauth"
          :style="$attrs.authorLabelClass"
          style="width: 250px; overflow: hidden; text-overflow: ellipsis; -webkit-line-clamp: 1;"
        >
          <template v-if="$attrs.showauthlabel">作者：</template>
          <template v-if="$attrs.book.ObjectType == '101' || $attrs.book.objectType == '101'">
            <div class="textflow1" style="width: 210px; height: 16px;"><span v-if="$attrs.book.bookEditorInChief">主编：{{ $attrs.book && $attrs.book.bookEditorInChief }}</span></div>
          </template>
          <template v-else>
            {{ $attrs.book && $attrs.book.Author }}
          </template>
          <template v-if="$attrs.showZhu">&ensp;著</template>
        </div>
        <template v-if="$attrs.showDetails">
          <div class="bookAuthor textflow1" v-if="$attrs.book && $attrs.book.Publisher">
            {{ $attrs.book && $attrs.book.Publisher }}
          </div>
          <div class="bookAuthor">
            <template v-if="$attrs.monthCn">
              {{ $attrs.book && $attrs.book.PublishDate | monthCn }}
            </template>
            <template v-else>
              {{ $attrs.book && $attrs.book.IssueDate | dateStr }}
            </template>
          </div>
          <div :style="$attrs.publishDateClass" v-if="$attrs.book.PublishDate">{{$attrs.book.PublishDate | dateStr}}</div>
        </template>
      </div>
      <!-- 课程内容 -->
      <template v-if="$attrs.showCourse">
        <div class="flexRowB bookAuthor timeBookAuthor">
          <span class="time">
            时长：{{ convertSecondsToHMS($attrs.book.WordCountOrDuration) }}
          </span>
          <span>课时：{{ $attrs.book && $attrs.book.NumberChapter }}</span>
        </div>
      </template>
      <!-- 专题 -->
      <template v-if="$attrs.seeCount">
        <div class="bookAuthorCb textflow1" style="width: 405px">
          {{ $attrs.book && $attrs.book.Publisher }}
        </div>
        <div class="flexRowB bookAuthor" style="color: rgba(141, 147, 153, 1); margin-top: 12px;">
          <span class="time" style="font-size: 14px; font-weight: 400; font-family: Noto Sans SC;">
            {{ $attrs.book && $attrs.book.PublishDate| dateStr }}
          </span>
          <span class="bookAuthorIcon">
            <img src="@/assets/images/icons/eye.png" />
            {{ $attrs.book && $attrs.book.readNum }}
          </span>
        </div>
      </template>
    </div>
    <div class="bottomFlexContainer" :class="[$attrs.bottomdetailstyle]">
      <template v-if="$attrs.showprice">
        <book-discount :book="$attrs.book"></book-discount>
      </template>
      <slot name="bottomDetailText" />
    </div>
  </div>
</div>
