
<div
  class="cursor bookContainer"
  v-if="$attrs.book"
  :key="$attrs.book.Id || $attrs.book.id"
  @click="goDetail($attrs.book)"
  :class="[
    $attrs.flexRow || $attrs.flexrow ? 'flexRowSB rowLayout' : 'normalLayout',
    $attrs.flexrow ? 'flexRowSB' : '',
    $attrs.shadow ? 'shadow' : '',
    $attrs.noPadding ? 'noPadding' : '',
    $attrs.largePadding ? 'largePadding' : '',
    $attrs.flexClass,
  ]"
  :style="$attrs.style || {}"
>
  <div
    class="flexGrow0 leftBookFace"
    :key="$attrs.book.Id || $attrs.book.id"
    :style="{
      width: $attrs.width,
      height: $attrs.height,
      background: `url(${
        ($attrs.book && $attrs.book.resourceImg) ||
        $attrs.book.CoverUrl ||
        $attrs.book.img ||
        $attrs.url ||
        $attrs.book.subImg ||
        $store.state.appConfig.ERR_IMG
      }) center center no-repeat`,
      backgroundSize: $attrs.bgsize || 'cover',
    }"
  >
    <div v-if="$attrs.book.exchangeType == 1" class="duiIcon"></div>
    <div v-if="$attrs.book.exchangeType == 2" class="quanIcon"></div>
    <div v-if="$attrs.showVideoIcon" class="videoIcon"></div>
    <div
      class="deleteIcon"
      v-if="$attrs.showdelete"
      @click.stop="deleteBook($attrs.book.id)"
    ></div>
    <div v-if="!$attrs.hidetype" class="flexRow bookTypeName">
      {{
        ($attrs.book && bookTypeNameMap[$attrs.book.ObjectType]) ||
        ($attrs.book && bookTypeNameMap[$attrs.book.objectType]) ||
        ($attrs.book && bookTypeNameMap[$attrs.book.resourceType]) ||
        ""
      }}
    </div>
  </div>
  <div
    class="flexGrow1 rightBootDetail"
    :class="[$attrs.rightFlex ? 'rightFlexRow' : '']"
  >
    <div
      class="topFixHeightContainer"
      :class="[
        $attrs.namePadding ? 'px18' : '',
        $attrs.resetNamePadding,
        $attrs.vertical ? 'contentVertical' : '',
      ]"
    >
      <slot name="bookName"></slot>
      <div
        v-if="!$attrs.hidename"
        class="bookName maxLine1"
        :class="[
          $attrs.titleline1 ? 'bookName1' : '',
          $attrs.titleline2 ? 'bookName2' : '',
          $attrs.titleline2small ? 'bookNameSmall' : '',
          $attrs.titlelinemax ? 'bookNameMax' : '',
          $attrs.nameCenter ? 'flexRow' : '',
        ]"
        v-text="
          $attrs.book
            ? $attrs.book.catalogueName ||
              $attrs.book.resourceName ||
              $attrs.book.bookName ||
              $attrs.book.Title ||
              $attrs.book.title ||
              $attrs.book.subTitle
            : ''
        "
      ></div>
      <!--    章节名称    -->
      <slot name="chapterName"></slot>
      <div>
        <!-- 作者 -->
        <div
          class="bookAuthor"
          :class="[$attrs.authCenter ? 'flexRow' : '']"
          v-if="!$attrs.hideauth"
        >
          <template v-if="$attrs.showauthlabel">作者：</template>
          <template
            v-if="$attrs.book.ObjectType == '101' || $attrs.book.objectType"
          >
            <template v-if="$attrs.book.bookEditorInChief">
              主编：{{ $attrs.book.bookEditorInChief }}
            </template>
          </template>
          <template v-else>
            {{ $attrs.book && $attrs.book.Author }}
          </template>
          <template v-if="$attrs.showZhu">&ensp;著</template>
        </div>
        <template v-if="$attrs.showDetails">
          <div class="bookAuthor" v-if="$attrs.book && $attrs.book.Publisher">
            {{ $attrs.book.Publisher }}
          </div>
          <div class="bookAuthor">
            <template v-if="$attrs.monthCn">
              {{ $attrs.book && $attrs.book.PublishDate | monthCn }}
            </template>
            <template v-else>
              {{ $attrs.book && $attrs.book.IssueDate | dateStr }}
            </template>
          </div>
        </template>
      </div>
      <!-- 课程内容 -->
      <template v-if="$attrs.showCourse">
        <div class="flexRowB bookAuthor timeBookAuthor">
          <span class="time">
            时长：{{ convertSecondsToHMS($attrs.book.WordCountOrDuration) }}
          </span>
          <span>课时：{{ $attrs.book && $attrs.book.NumberChapter }}</span>
        </div>
      </template>
      <!-- 专题 -->
      <template v-if="$attrs.seeCount">
        <div class="bookAuthorCb">
          {{ $attrs.book && $attrs.book.publisher }}
        </div>
        <div class="flexRowB bookAuthor" style="color: #666">
          <span class="time">
            {{ $attrs.book && $attrs.book.publishDate | dateStr }}
          </span>
          <span class="bookAuthorIcon">
            <img src="@/assets/images/icons/eye.png" />
            {{ $attrs.book && $attrs.book.readNum }}
          </span>
        </div>
      </template>
    </div>
    <div class="bottomFlexContainer" :class="[$attrs.bottomdetailstyle]">
      <template v-if="$attrs.showprice">
        <book-discount :book="$attrs.book"></book-discount>
      </template>
      <slot name="bottomDetailText" />
    </div>
  </div>
</div>
